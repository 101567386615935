// initial state
import getEnv from '@/utils/env'

const REQUEST_URL = getEnv('REQUEST_URL');

import axios from 'axios';

const state = () => ({
  likesData:[],
});

// getters
const getters = {}

// actions
const actions = {

  async toggle(context, params) {
    // console.log('create: ' + state.postData);
    return axios.post(REQUEST_URL + "like/toggle",{
      pic_id: params.imageid,
      type: params.type,
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      // context.commit('setRequest', res.data);
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async likeit(context, params) {
    // console.log('create: ' + state.postData);
    return axios.post(REQUEST_URL + "likes",{
      pic_id: params.imageid,
      type: params.type,
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      // context.commit('setRequest', res.data);
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async show(context, params) {
    // console.log('create: ' + state.postData);
    axios.get(REQUEST_URL + "like/photousers?pikid=" + params.imageid,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      context.commit('setLikes', res.data);
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
}

// mutations
const mutations = {
  setLikes(state, response) {
    state.likesData = response;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
