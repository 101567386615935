/* import axios from 'axios';

export const HTTP = axios.create({
  baseURL: 'https://dev.pikmobile.com/',
  headers: {
    Authorization: 'Bearer 03ddd5ac50ee165737e937032d61af05220ff131'
  }
}); */
import Vuex from 'vuex'

import auth from './modules/auth'
import pikstream from './modules/pikstream'
import profile from './modules/profile'
import post from './modules/post'
import helpers from './modules/helpers'
import groups from './modules/groups'
import premium from './modules/premium'
import postedit from './modules/postedit'
import likes from './modules/likes'
import comments from './modules/comments'
import gifs from './modules/gifs'
import explore from './modules/explore'
import featured from './modules/featured'
import header from './modules/header'
import messages from './modules/messages'
import elections from './modules/elections'
// import createLogger from '../../../src/plugins/logger'

// const debug = import.meta.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    helpers,
    pikstream,
    premium,
    profile,
    post,
    groups,
    postedit,
    likes,
    comments,
    gifs,
    explore,
    featured,
    header,
    messages,
    elections
  }
  // strict: debug,
  // plugins: debug ? [createLogger()] : []
})
