// initial state
import getEnv from '@/utils/env'

const REQUEST_URL = getEnv('REQUEST_URL');

var LOGIN_URL = REQUEST_URL + 'auth/token';
var SIGNUP_URL = REQUEST_URL + 'user/create';
var LOGOUT_URL = REQUEST_URL + 'user/logout';
var RESET_URL = REQUEST_URL + 'user/resetpass-link';
var CHANGEPW_URL = REQUEST_URL + 'user/changepass';

var SIGNUP_TOKEN = 'Bearer ' + getEnv('SIGNUP_TOKEN');

import axios from 'axios';
const state = () => ({
  token: localStorage.getItem('token') || '',
  refreshToken: localStorage.getItem('refreshToken') || '',
  username: localStorage.getItem('username') || '',
  anonToken: SIGNUP_TOKEN,
  fsbnToken: '',
  email: '',
  fullname: '',
  phone: ''
})

// getters
const getters = {}

// actions
const actions = {
  async login(context, credentials) {
    return axios.post(LOGIN_URL, {
      username: credentials.username,
      password: credentials.password,
      grant_type: "password",
      client_id: "webapp",
      client_secret:  getEnv('SIGNUP_TOKEN2')
    })
    .then(res => {
      //return res.data.access_token;
      localStorage.setItem('username', credentials.username)
      context.commit('setUsername', credentials.username);
      localStorage.setItem('token', 'Bearer ' + res.data.access_token)
      context.commit('setToken', 'Bearer ' + res.data.access_token);
      localStorage.setItem('refreshToken', res.data.refresh_token)
      context.commit('setRefreshToken', res.data.refresh_token);
      return true;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async idAuthToken(context, credentials) {
    return axios.post(REQUEST_URL + 'user/fsbn-login', {
      fsbntoken: credentials.fsbntoken
    })
    .then(res => {
      if(res.data.error!=undefined){
        // no data was found neither did frankspeech connect
        return {error: res.data.error};
      }else{
        if(res.data.access_token!=undefined){
          // user found you can log them in now
          localStorage.setItem('username', credentials.username)
          context.commit('setUsername', credentials.username);
          localStorage.setItem('token', 'Bearer ' + res.data.access_token)
          context.commit('setToken', 'Bearer ' + res.data.access_token);
          localStorage.setItem('refreshToken', res.data.refresh_token)
          context.commit('setRefreshToken', res.data.refresh_token);
          return true;
        }else if(res.data.user_account_id!=undefined){
          // NOTE: user not found in the system you can setup a new account now
          localStorage.setItem('username', res.data.username)
          context.commit('setUsername', res.data.username);
          context.commit('setEmail', res.data.email);
          context.commit('setFullname', res.data.fullname);
          context.commit('setPhone', res.data.phone);
          context.commit('setFsbn', res.data.user_account_id);
          // add the profile data here    
          return res.data;
        }else{
          return { error: res.data };
        }
      }
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async refreshToken(context) {
    return axios.post(LOGIN_URL, {
      refresh_token: context.state.refreshToken,
      grant_type: "refresh_token",
      client_id: "webapp",
      client_secret:  getEnv('SIGNUP_TOKEN2')
    })
    .then(res => {
      //return res.data.access_token;
      localStorage.setItem('token', 'Bearer ' + res.data.access_token)
      context.commit('setToken', 'Bearer ' + res.data.access_token);
      localStorage.setItem('refreshToken', res.data.refresh_token)
      context.commit('setRefreshToken', res.data.refresh_token);
      return true;
    })
    .catch(error => {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      context.commit('setToken', '');
      context.commit('setRefreshToken', '');
      // you should set a flag to show a session expired box on the login page
      return({'error': error.message});
    });
  },
  async reset(context, credentials) {
    return axios.post(RESET_URL,
      {
        email: credentials.email,
      },
      {
        headers: {
          'Authorization': SIGNUP_TOKEN
        }
      })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async changepass(context, credentials) {
    return axios.post(CHANGEPW_URL,
      {
        email: credentials.email,
        token: credentials.token,
        password: credentials.password,
      },
      {
        headers: {
          'Authorization': SIGNUP_TOKEN
        }
      })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async signup(context, credentials) {
    return axios.post(SIGNUP_URL,
      {
        user_name: credentials.username,
        access: credentials.password,
        email: credentials.email,
        phone: credentials.phone,
        fsbn: context.state.fsbnToken
      },
      {
        headers: {
          'Authorization': SIGNUP_TOKEN
        }
      })
    .then(res => {
      //console.log(res.data);
      var errorUsername = res.data.user_name;
      var errorEmail = res.data.email;
      var errorPhone = res.data.phone;
      var errorUsernameOther = res.data.message;
      var validated = true;

      var user = '';
      var email = '';
      var phone = '';
      
      // console.log(response['user_name'][0][0]);
      if(errorUsername=="This username is already being used."){
        user = "This username is already being used. ";
        validated = false;
      }
      if(errorEmail=="This email address is already being used."){
        email = "This email address is already being used. " ;
        validated = false;
      }
      if(errorPhone=="This phone number is already being used."){
        phone = "This phone number is already being used. ";
        validated = false;
      }
      if(errorUsernameOther=="bad word error"){
        user = "Curse words are not allowed. ";
        validated = false;
      }
      if(errorUsernameOther=="reserved username error"){
        user = "We reserved this username anticipating your arrival! We need to verify it’s you to release it. Email us with your username, and we’ll get the verification process started! contact@vocl.com";
        validated = false;
      }
      
      if(validated!=true){
        return({'error':'','errorEmail': email,'errorUsername': user,'errorPhone': phone});
      }else{
        localStorage.setItem('username', res.data.username)
        context.commit('setUsername', res.data.username);
        localStorage.setItem('token', 'Bearer ' + res.data.access_token)
        context.commit('setToken', 'Bearer ' + res.data.access_token);
        localStorage.setItem('refreshToken', res.data.refresh_token)
        context.commit('setRefreshToken', res.data.refresh_token);
        return true;
      }
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async logout(context) {
    return axios.post(LOGOUT_URL, {
      access_token: context.state.token,
      refresh_token: context.state.refreshToken
    },
    {
      headers: {
        'Authorization': context.state.token
      }
    })
    .then(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      context.commit('setToken', '');
      context.commit('setRefreshToken', '');
      return true;
    })
    .catch(() => {
      // remove it anyway in case there is an error
      console.log('catch it anyway');
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      context.commit('setToken', '');
      context.commit('setRefreshToken', '');
      return true;
    });

  }
}

// mutations
const mutations = {
  setToken(state, t) {
    state.token = t;
  },
  setRefreshToken(state, t) {
    state.refreshToken = t;
  },
  setUsername(state, t) {
    state.username = t;
  },
  setFsbn(state, t) {
    state.fsbnToken = t;
  },
  setEmail(state, t) {
    state.email = t;
  },
  setFullname(state, t) {
    state.fullname = t;
  },
  setPhone(state, t) {
    state.phone = t;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
