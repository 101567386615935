<template>
    <img @click="openPopover($event, 1)" :title="getEnv('APP_NAME') + ' ' + $t('buttons.verified')"
        :alt="getEnv('APP_NAME') + ' ' + $t('buttons.verified')" v-if="showBadge(1)"
        style="margin: 0px 5px 0px 0px; width:14px; height:14px;" src="/assets/icon/certified.png">
    <img @click="openPopover($event, 'ecb')" :title="ecbText"
        :alt="ecbText" v-if="showBadge('ecb')" 
        style="margin: 0px 5px 0px 0px; width:14px; height:14px;" src="/assets/ecb-logo-sm.png">
    <img @click="openPopover($event, 2)" :title="greenText"
        :alt="greenText" v-if="showBadge(2)"
        style="margin: 0px 5px 0px 0px; width:14px; height:14px;" src="/assets/count-green.png">
    <img @click="openPopover($event, 3)" :title="purpleText"
        :alt="purpleText" v-if="showBadge(3)"
        style="margin: 0px 5px 0px 0px; width:14px; height:14px;" src="/assets/count-purple.png">
    <img @click="openPopover($event, 4)" :title="redText"
        :alt="redText" v-if="showBadge(4)"
        style="margin: 0px 5px 0px 0px; width:14px; height:14px;" src="/assets/count-red.png">
    <img @click="openPopover($event, 5)" :title="goldText"
        :alt="goldText" v-if="showBadge(5)"
        style="margin: 0px 5px 0px 0px; width:14px; height:14px;" src="/assets/count-gold.png">
    <ion-popover :is-open="popoverOpen" :event="event" @didDismiss="popoverOpen = false">
        <ion-content class="ion-padding">{{ popupText }}</ion-content>
    </ion-popover>
</template>

<script>
import { IonContent, IonPopover } from '@ionic/vue';
import { closeOutline, people, checkmark, closeCircle } from 'ionicons/icons';
import getEnv from '@/utils/env';

export default {
    name: 'UserBadges',
    props: {
        certified: { Number, default: 0 },
        ecb:  { Number, default: 0 }
    },
    components: { IonContent, IonPopover },
    setup() {
        return {
            closeOutline,
            people,
            checkmark,
            closeCircle,
            getEnv
        }
    },
    data() {
      return {
        popoverOpen: false,
        event: null,
        popupText: '',
        greenText: 'Attended hand count training',
	    purpleText: 'Qualified as a hand count trainer',
        redText: 'Taught 10 hand count classes',
        goldText: 'Trained at least 100 trainers',
        ecbText: 'Member of the Election Crime Bureau'
      };
    },
    methods: {
        showBadge(badgeType) {
            let flag = false;
            if (badgeType == 1 && this.certified != undefined) {
                flag = true;
            } else if (badgeType == 'ecb' && this.ecb == 1) {
                flag = true;
            } else {
                if (this.certified == badgeType) {
                    flag = true;
                }
            }
            return flag;
        },
        openPopover(e, type) {
            this.event = e;
            this.popoverOpen = true;
            switch (type) {
                case 1:
                    this.popupText = getEnv('APP_NAME') + ' ' + this.$t('buttons.verified');
                    break;
                case 2:
                    this.popupText = this.greenText;
                    break;
                case 3:
                    this.popupText = this.purpleText;
                    break;
                case 4:
                    this.popupText = this.redText;
                    break;
                case 5:
                    this.popupText = this.goldText;
                    break;
                case 'ecb':
                    this.popupText = this.ecbText;
                    break;

                default:
                    break;
            }
        },
    }
}
</script>