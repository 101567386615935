// initial state
import getEnv from '@/utils/env'

const REQUEST_URL = getEnv('REQUEST_URL');

import axios from 'axios';

const state = () => ({
  premiumData:[],
  premiumPrices: [],
  info: [],
  customerKey: '',
  receiptList: [],
  receiptDetail: [],
  premiumReceipts: []
})

// getters
const getters = {}

// actions
const actions = {

  priceList(context) {
    axios.get(REQUEST_URL + 'purchase/pricelist',
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      context.commit('setPremiumPrices', res.data);
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  purchaseList(context) {
    console.log('getting receipts');
    axios.get(REQUEST_URL + 'purchase/subscription-list',
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      context.commit('setReceiptList', res.data);
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  purchaseDetail(context, params) {
    let url = 'purchase/subscription-details?total_list=' + params.total_list + '&current_page=' + params.current_page;
    if(params.payment_id!='') url = url + '&payment_id=' + params.payment_id;
    if(params.premium_user_id!='') url = url + '&premium_user_id=' + params.premium_user_id;
    if(params.storefront_id!='') url = url + '&storefront_id=' + params.storefront_id;
    axios.get(REQUEST_URL + url,
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      console.log(res.data);
      context.commit('setReceiptDetail', res.data);
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  salesList(context, params) {
    axios.get(REQUEST_URL + 'purchase/subscription-receipts?current_page=' + params.current_page + '&total_list=' + params.total_list,
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      context.commit('setPremiumReceipts', res.data);
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async checkout(context, params) {
    //console.log(params);
    return axios.post(REQUEST_URL + 'purchase/web-checkout-init',{
        premium_user_id: params.premium_user_id,
        product_price: params.product_price,
        product_length: params.product_length,
        account_upgrade: params.account_upgrade,
        payment_type: params.payment_type
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async updateCard(context, params) {
    //console.log(params);
    return axios.post(REQUEST_URL + 'purchase/customer-init',{
        receipt_id: params.receipt_id,
        account_upgrade: params.account_upgrade
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async cancelSub(context, params) {
    //console.log(params);
    return axios.post(REQUEST_URL + 'purchase/cancel-sub',{
        receipt_id: params.receipt_id,
        account_upgrade: params.account_upgrade
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async updateProfile(context, params) {
    console.log(params);
    var values = {
      first_name: params.first_name,
      company: params.company,
      email: params.email,
      phone: params.phone,
      address: params.address,
      city: params.city,
      state: params.state,
      zipcode: params.zipcode,
      premium_info: params.premium_info,
      premium_price: params.premium_price,
      premium_content: params.premium_content,
      fixed_price: params.fixed_price,
      one_time_payment: params.one_time_payment
    }

    return axios.post(REQUEST_URL + "user/updateme", values,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      if(res.data['message']==null && res.data['error']==null){
        this.commit('profile/setRequest', res.data);
      }
      return res.data; 
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  /* async finalize(context, params) {
    return axios.post(REQUEST_URL + 'storefront/web-checkout-complete',{
      sub_id : params.sub_id,
      sub_price : params.sub_price,
      subscriptionId : params.subscriptionId,
      subscription_stop_date : params.subscription_stop_date,
      $subscription_start_date : params.subscription_start_date
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  }, */
  info(context, params) {
    axios.get(REQUEST_URL + 'storefront/storedetail?id=' + params.id + '&stripe=1',
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      context.commit('setInfo', res.data);
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  /* add(context, params) {
    axios.get(REQUEST_URL + '/piklist/search?search=' + params.search + '&type=' + params.type,
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      context.commit('setGroup', res.data);
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  delete(context, params) {
    // storefront_id add in if needed
    axios.get(REQUEST_URL + '/friend/mutual?mutualtype=' + params.mutualtype + '&currentPage=' + params.currentPage + '&totalList=' + params.totalList,
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      context.commit('setGroup', res.data);
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  edit(context, params) {
    axios.get(REQUEST_URL + '/friend/search?search=' + params.search,
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      context.commit('setGroup', res.data);
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  }, */
}

// mutations
const mutations = {
  setPremium(state, response) {
    state.premiumData = response;
  },
  setInfo(state, response) {
    state.info = response;
  },
  setPremiumPrices(state, response) {
    state.premiumPrices = response;
  },
  setReceiptList(state, response){
    console.log(response, 'receipt list');
    state.receiptList = response;
  },
  setReceiptDetail(state, response){
    state.receiptDetail = response;
  },
  setPremiumReceipts(state, response){
    state.premiumReceipts = response;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
