// initial state
import getEnv from '@/utils/env'

const REQUEST_URL = getEnv('REQUEST_URL');

import axios from 'axios';

const state = () => ({
  commentData:[],
  addComment: 0
});

// getters
const getters = {}

// actions
const actions = {

  async post(context, params) {
    // console.log('create: ' + state.postData);
    return axios.post(REQUEST_URL + "comments",{
      pic_id: params.imageid,
      comment: params.comment,
      parent_id: params.parent_id,
      comment_id: params.comment_id,
      gif_url: params.gif_url,
      gif_width: params.gif_width,
      gif_height: params.gif_height,
      link_url: params.link_url,
      link_text: params.link_text,
      link_image: params.link_image
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      // context.commit('setRequest', res.data);
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async like(context, params) {
    // console.log('create: ' + state.postData);
    return axios.post(REQUEST_URL + "comment-likes",{
      comment_id: params.id,
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      // context.commit('setRequest', res.data);
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async delete(context, params) {
    // console.log('create: ' + state.postData);
    return axios.delete(REQUEST_URL + "comments/" + params.id,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async show(context, params) {
    // console.log('create: ' + state.postData);
    return axios.get(REQUEST_URL + "comment/photo?pikid=" + params.imageid + "&currentPage=" + params.currentPage,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      if(params.currentPage>0){
        context.commit('addToComments', [res.data, params.imageid]);
      }else{
        context.commit('setComments', [res.data, params.imageid]);
      }
      return true;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
}

// mutations
const mutations = {
  setComments(state, response) {
    let responseData = response[0];
    const postId = response[1];
    state.commentData[postId] = responseData;
    //state.commentData = response;
  },
  setAddComment(state, response) {
    state.addComment = response;
  },
  addToComments(state, response) {
    const responseData = response[0];
    const imageId = response[1];
    
    var j = state.commentData[imageId].length;
    const max = responseData.length;
    const min = 0;
    for (let i = min; i < max; i++) {
      state.commentData[imageId][j] = responseData[i];
      j = j + 1;
    }
    /* var j = state.commentData.length;
    const max = response.length;
    const min = 0;
    for (let i = min; i < max; i++) {
      state.commentData[j] = response[i];
      j = j+1;
    }*/
  },
  logout(state){
    state.commentData = [];
    state.addComment = 0;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
