// initial state
import getEnv from '@/utils/env'

const REQUEST_URL = getEnv('REQUEST_URL');

import axios from 'axios';

const state = () => ({
  gifData:[],
});

// getters
const getters = {}

// actions
const actions = {

  search(context, params) {
    // user_id optional
    var urlAdd = '';
    if(params.pos>0) urlAdd = "&pos=" + params.pos;
    var url = 'https://g.tenor.com/v1/search?q=' + params.search + '&key=IG55BX1KYU4X&limit=' + params.limit + '&contentfilter=high&media_filter=basic&local=en_US' + urlAdd;

    axios.get(url)
    .then(res => {
      //context.commit('setGifsSearch', res.data);
      if(params.update){
        context.commit('setGifs', res.data);
      }else{
        context.commit('setGifsSearch', res.data);
      }
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  browse(context) {
    var url = "https://api.tenor.com/v1/trending?key=IG55BX1KYU4X&limit=24&contentfilter=high&media_filter=basic&local=en_US";
    axios.get(url)
    .then(res => {
      context.commit('setGifsSearch', res.data);
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
}

// mutations
const mutations = {
  setGifs(state, response) {
    let existingGifs = state.gifData;
    // console.log(existingGifs);
    let updatedArray = existingGifs.concat(response.results);

    state.gifData = updatedArray;

  },
  setGifsSearch(state, response) {
    if(response!=null){
      state.gifData = response.results;
    }else{
      state.gifData = [];
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
