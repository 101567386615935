<template>
  <ion-app>
    <ion-router-outlet  v-if="isPlatform('iphone') || isPlatform('android')" ></ion-router-outlet> 
    <SplitPane v-else />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import SplitPane from '@/views/SplitPane.vue'
//import TabsPage from '@/views/TabsPage.vue'
import { isPlatform } from '@ionic/vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    SplitPane,
    IonRouterOutlet
  },
  setup(){
    return {
      isPlatform
    }
  }
});

</script>
