// initial state
import getEnv from '@/utils/env'

const REQUEST_URL = getEnv('REQUEST_URL');

import axios from 'axios';

const state = () => ({
  messageData:[],
  messageThreadData: null,
  threadData:[],
  newMessages: []
});

// getters
const getters = {}

// actions
const actions = {

  async newThread(context, params) {
    // console.log('create: ' + state.postData);
    return axios.post(REQUEST_URL + "messages/create-thread", {
        thread_type: params.thread_type,
        thread_name: params.thread_name,
        thread_users: params.thread_users,
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      // context.commit('setRequest', res.data);
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async send(context, params) {
    // console.log('create: ' + state.postData);
    return axios.post(REQUEST_URL + "messages/send-message",{
        thread_id: params.thread_id,
        message: params.message,
        piklist_id: params.piklist_id,
        link_url: params.link_url,
        link_text: params.link_text,
        link_image: params.link_image,
        gif_url: params.gif_url,
        gif_width: params.gif_width,
        gif_height: params.gif_height
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      // context.commit('setRequest', res.data);
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async addMember(context, params) {
    // console.log('create: ' + state.postData);
    return axios.post(REQUEST_URL + "messages/add-user",{
      thread_id: params.thread_id,
      user_id: params.user_id,
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async deleteMember(context, params) {
    // console.log('create: ' + state.postData);
    return axios.post(REQUEST_URL + "messages/remove-user",{
      thread_id: params.thread_id,
      user_id: params.user_id,
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async updateGroupName(context, params) {
    // console.log('create: ' + state.postData);
    return axios.post(REQUEST_URL + "messages/group-name",{
      thread_id: params.thread_id,
      thread_name: params.thread_name,
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  /* async delete(context, params) {
    // console.log('create: ' + state.postData);
    return axios.delete(REQUEST_URL + "messages/" + params.id,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },*/
  async allThreads(context) {
    return axios.get(REQUEST_URL + "messages/threads",
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      context.commit('setThreads', res.data);
      return true;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async threadDetail(context, params) {
    return axios.get(REQUEST_URL + "messages/thread-detail?thread_id=" + params.thread_id,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async threadPoll(context) {
    return axios.get(REQUEST_URL + "messages/thread-poll",
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      context.commit('setNewMessages', res.data);
      return true;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async newMessages(context, params) {
    return axios.get(REQUEST_URL + "messages/new-messages?thread_id=" + params.thread_id,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
        context.commit('addNewMessages', res.data);
        return res.data;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async showThread(context, params) {
    let vars = "";
    if(params.thread_id!=undefined){
        vars = "?thread_id=" + params.thread_id;
    }else{
        return {'error': 'no thread'};
    } 
   
    if(params.paging_date_time!=undefined) vars = vars + "&paging_date_time=" + params.paging_date_time;
    if(params.page!=undefined) vars = vars + "&page=" + params.page;
    if(params.page_size!=undefined) vars = vars + "&page_size=" + params.page_size;
    //console.log(REQUEST_URL + "messages/view-thread" + vars);
    return axios.get(REQUEST_URL + "messages/view-thread" + vars,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
        if(params.load_more){
            context.commit('addToMessages', res.data);
        }else{
            context.commit('setMessages', res.data);
        } 
        return true;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
}

// mutations
const mutations = {
  setMessages(state, response) {
    state.messageData = response;
  },
  setThreadDetail(state, response) {
    state.messageThreadData = response;
  },
  setThreads(state, response) {
    state.threadData = response;
  },
  setNewMessages(state, response) {
    state.newMessages = response;
  },
  setAddmessage(state, response) {
    state.addmessage = response;
  },
  addToMessages(state, response) {
    const min = 0;
    const max = (response.length-1);
    for (let i = max; i >= min; i--) {
      state.messageData.unshift(response[i]);
    }
  },
  addNewMessages(state, response) {
    const min = 0;
    const max = response.length;
    for (let i = min; i < max; i++) {
      state.messageData.push(response[i]);
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
