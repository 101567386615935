<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-back-button slot="start"></ion-back-button>
        <ion-title>{{ getEnv('APP_NAME') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div id="entry-parent" :class="randomBack()">
        <div id="content-box">
          <SignupContainer />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, isPlatform, IonHeader, IonToolbar, IonBackButton, IonTitle } from '@ionic/vue';
import '../theme/signup.css';
import SignupContainer from '@/components/SignupContainer.vue';
import getEnv from '@/utils/env'

export default {
  name: "SignupView",
  components: { IonContent, IonPage, SignupContainer, IonHeader, IonToolbar, IonBackButton, IonTitle },
  setup() {
    return { isPlatform, getEnv }
  },
  data() {
    return {
      dismissFlag: false,
      backVal: 1
    }
  },
  mounted() {
    if(getEnv('APP_NAME') == 'ChoiceSocial'){
      this.backVal = Math.floor(Math.random() * (7 - 1) + 1);
    }
  },
  methods: {
    randomBack() {
      return 'background-' + this.backVal;
    }
  },
};
</script>