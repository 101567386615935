<template>
  <ion-header :translucent="true">
    <ion-toolbar>
      <ion-buttons slot="secondary">
        <ion-button @click="closeModal">
          <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>{{ title }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content fullscreen>
    <ion-list>
      <ion-item>
        <ion-textarea class="post-box" v-model="feedbackInput" :placeholder="$t('placeholder.feedback')" spellcheck rows="4"></ion-textarea>
      </ion-item>
    </ion-list>
    <ion-button expand="full" @click="sendFeedback">{{ $t('buttons.submit') }}</ion-button>
  </ion-content>
</template>


<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonIcon, IonButtons, IonButton, modalController, loadingController, IonTextarea, IonList } from '@ionic/vue';
import { closeOutline, bookmarks, people, closeCircle, camera } from 'ionicons/icons';

export default {
  name: 'FeedbackModal',
  props: {
    title: String,
  },
  setup() {
    const isDisabled = false;
    const closeModal = () => {
      modalController.dismiss();
    };

    return {
      closeOutline,
      closeModal,
      isDisabled,
      bookmarks,
      people,
      closeCircle,
      camera
    }
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonButtons, IonButton, IonIcon, IonList, IonTextarea },
  data(){
    return {
      error: '',
      loadingIndicator: '',
      feedbackInput: ''
    }
  },
  methods: {
    async sendFeedback() {
      if(this.feedbackInput!=""){
        await this.presentLoading();
        //console.log('story: ' + this.story.piklist_id + 'image: ' + this.image.id + 'desc: ' + this.captionInput);
        let response = await this.$store.dispatch("helpers/feedback", {
          feedback: this.feedbackInput,
        });
        if (response) {
          if(response['error']!=null){
            this.loadingIndicator.dismiss();
            console.log('Error: ' + response['error']);
            console.log(response);
          } else {
            // you can add condition here
            this.loadingIndicator.dismiss();
          }
          modalController.dismiss();
        }
      }
    },
    async presentLoading() {
      this.loadingIndicator = await loadingController
        .create({
          cssClass: 'loading-class',
          message: this.$t('loadmessage.feedback'),
        });
      await this.loadingIndicator.present();
    },
  },
}
</script>
