<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-back-button slot="start"></ion-back-button>
        <ion-title>{{ getEnv('APP_NAME') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div id="entry-parent" :class="randomBack()">
        <div id="content-box">
          <div class="form-box" style="width:100%; padding:10px 0px;">
            <ion-grid>
              <ion-row class="ion-padding ion-text-center">
                <ion-col>
                  <a href="/">
                    <div id="logo-image"></div>
                  </a>
                </ion-col>
              </ion-row>
            </ion-grid>
            <ion-list>
              <ion-item>
                <ion-input autocomplete="new-password" type="password" :placeholder="$t('placeholder.createnewpass')"
                  v-model="newPassword"></ion-input>
              </ion-item>
              <ion-item>
                <ion-input autocomplete="new-password" type="password" :placeholder="$t('placeholder.confirmnewpass')"
                  v-model="newPassword2"></ion-input>
              </ion-item>
            </ion-list>
            <div v-show="error != ''" class="error-block"><ion-note color="light">{{ error }}</ion-note></div>
            <ion-button expand="block" class="login-button" v-on:click="changepass()">{{ $t('buttons.changepass')
              }}</ion-button>
            <ion-button expand="block" class="help-button" v-on:click="$router.push('/');">{{ $t('buttons.back')
              }}</ion-button>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import { IonPage, IonContent, IonButton, IonInput, IonNote, loadingController, IonGrid, IonCol, IonRow, IonList, IonItem, IonHeader, IonToolbar, IonBackButton, IonTitle, alertController } from '@ionic/vue';
import '../theme/signup.css';
import getEnv from '@/utils/env';

export default {
  name: "ChangePass",
  components: { IonContent, IonPage, IonButton, IonInput, IonNote, IonGrid, IonCol, IonRow, IonList, IonItem, IonHeader, IonToolbar, IonBackButton, IonTitle, },
  data() {
    return {
      error: '',
      loadingIndicator: '',
      newPassword: '',
      newPassword2: '',
      backVal: 1
    }
  },
  setup() {
    return {
      getEnv
    }
  },
  mounted() {
    if(getEnv('APP_NAME') == 'ChoiceSocial'){
      this.backVal = Math.floor(Math.random() * (7 - 1) + 1);
    }
  },
  methods: {
    async changepass() {
      var validated = this.validateFields();
      if(validated != ""){
        this.error = validated;
        // this.presentAlert();
      }else{
        this.presentLoading();
        let response = await this.$store.dispatch("auth/changepass", {
          password: this.newPassword,
          email: this.$route.query.email,
          token: this.$route.query.token
        });
        if (response) {
          if(response['error']!=null){
            this.loadingIndicator.dismiss();
            console.log('Error: ' + response['error']);
            console.log(response);
            if(response['error']=="Request failed with status code 401"){
              this.error = this.$t('errors.incorrectlogin');
            }else{
              this.error = response['error'];
            }
          }else{
            this.error = '';
            this.newPassword = '';
            this.newPassword2 = '';
            this.loadingIndicator.dismiss();
            this.presentAlert();
          }

        } else {
          // you can add condition here
          this.loadingIndicator.dismiss();
          this.error = this.$t('errors.unknown');
        }
      }
    },
    validateFields(){
      var validateFields = "";
      if(this.newPassword==undefined || this.newPassword=='' || this.newPassword.length < 4){
        validateFields = validateFields + this.$t('errors.passlength');
      }
      if(this.newPassword!=this.newPassword2){
        validateFields = validateFields +  this.$t('errors.passmatch');
      }
      if(this.$route.query.email == null || this.$route.query.token == null){
        validateFields = validateFields +  this.$t('errors.invalidtoken');
      }
      return validateFields;
    },
    async presentAlert() {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: this.$t('alerthead.passreset'),
          message: this.$t('alertmessage.passreset'),
          buttons: [
            {
              text: 'Login',
              handler: () => {
                this.$router.replace("/login");
              },
            },
          ],
        });
      return alert.present();
    },
    async presentLoading() {
      this.loadingIndicator = await loadingController
        .create({
          cssClass: 'loading-class',
          message: this.$t('loadmessage.passreset'),
        });
      await this.loadingIndicator.present();
    },
    randomBack() {
      return 'background-' + this.backVal;
    }
  },
};
</script>

<style>
.login-button{
  --background: rgba(100,100,100,1.0);
  margin-top: 20px;
}

.error-label{
  padding-top:25px;
  color: lightgray;
}

.loading-class {
  --background: #222;
  --spinner-color: #fff;
  color: #fff;
}

</style>
