// initial state
import getEnv from '@/utils/env'

const REQUEST_URL = getEnv('REQUEST_URL');

import axios from 'axios';

const state = () => ({
  groupData: [],
  userGroupData: [],
  userGroupsData: null,
  featuredData: null,
  sectionData: null,
  myGroupsData: null,
  categoryData: null,
  groupProfile: [],
  groupRules: null,
  membersData: [],
  joinRequestData: null,
  userAnswers: null,
  blockedData: null,
  filesData: []
})

// getters
const getters = {}

// actions
const actions = {

  list(context) {
    axios.get(REQUEST_URL + 'groups',
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      context.commit('setGroup', res.data);
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async view(context, params) {
    var token = context.rootState.auth.anonToken;
    if (context.rootState.auth.token != '') {
      token = context.rootState.auth.token;
    }

    return axios.get(REQUEST_URL + 'gp/user-groups/' + params.id,
    {
      headers: {
        'Authorization': token
      }
    })
    .then(res => {
      context.commit('setGroupProfile', [params.id, res.data]);
      return res.data;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async search(context, params) {
    return axios.get(REQUEST_URL + 'gp/user-group/search?name=' + params.name,
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        context.commit('setUserGroups', res.data);
        return true;
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  rules(context, params) {
    axios.get(REQUEST_URL + 'gp/user-group/rules/?id=' + params.id,
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      context.commit('setGroupRules', res.data);
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  answers(context, params) {
    axios.get(REQUEST_URL + 'gp/user-group-answers/' + params.id + '?group_id=' + params.group_id,
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      context.commit('setAnswers', res.data);
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  requests(context, params) {
    axios.get(REQUEST_URL + 'gp/user-group-member/requests/?id=' + params.id,
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      context.commit('setRequests', res.data);
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async members(context, params) {
    var token = context.rootState.auth.anonToken;
    if (context.rootState.auth.token != '') {
      token = context.rootState.auth.token;
    }
    let addRequest = '';
    if(params.type=='admin'){
      addRequest = '&type=admin';
    }
    if(params.page!=undefined){
      addRequest = addRequest + '&page=' + params.page;
    }
    if(params.total!=undefined){
      addRequest = addRequest +  '&total=' + params.total;
    }
    return axios.get(REQUEST_URL + 'gp/user-group-members?id=' + params.id + addRequest,
    {
      headers: {
        'Authorization': token
      }
    })
    .then(res => {
      if(params.page>0){
        context.commit('setAddToMembers', [res.data, params.id]);
      }else{
        context.commit('setMembers', [res.data, params.id]);
      }
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async all(context, params) {
    let url = '';
    if(params != undefined){
      //console.log(params, 'params');
      if(params.groupType != undefined && params.groupType != 0){
        url = url.concat('category=' + params.groupType + '&');
      }
      if(params.page != undefined){
        url = url.concat('page=' + params.page  + '&');
      }
      if(params.total != undefined){
        url = url.concat('total=' + params.total + '&');
      }
      if(url != ''){
        url = '?' + url.slice(0, -1).trim();
      }
    }
    return axios.get(REQUEST_URL + 'gp/user-groups' + url,
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      if(params!=undefined && params.page>0){
        context.commit('setAddUserGroups', res.data);
      }else{
        context.commit('setUserGroups', res.data);
      }
      //console.log(res.data.length);
      if(res.data!=undefined && res.data.length==0){
        return false;
      }
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  feature(context) {
    axios.get(REQUEST_URL + 'gp/user-group/featured',
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      context.commit('setFeaturedGroups', res.data);
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  section(context, params) {
    let url = '';
    for(let i=0; i<params.categories.length; i++){
      url = url + 'categories[]=' + params.categories[i] + '&';
    }
    url = '?' + url.slice(0, -1).trim();

    axios.get(REQUEST_URL + 'gp/user-group/section' + url,
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      context.commit('setSectionGroups', res.data);
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async joined(context, params) {
    let total = 6;
    let page = 0;
    if(params != undefined){
      if(params.page != undefined){
        page = params.page;
      }
      if(params.total != undefined){
        total = params.total;
      }
    }
    return axios.get(REQUEST_URL + 'gp/user-group/joined?page=' + page + '&total=' + total,
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      if(params!=undefined && params.page>0){
        context.commit('setAddMyGroups', res.data);
      }else{
        context.commit('setMyGroups', res.data);
      }     
      if(res.data!=undefined && res.data.length==0){
        return false;
      }
      return false;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  blocked(context, params) {
    axios.get(REQUEST_URL + 'gp/user-group-blocks?id=' + params.id,
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      //console.log(res.data, 'blocked data');
      context.commit('setBlocked', res.data);
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async unblock(context, params) {
    return axios.delete(REQUEST_URL + 'gp/user-group-blocks/' + params.id,
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      context.commit('setBlocked', res.data);
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  categories(context) {
    axios.get(REQUEST_URL + 'gp/user-group-categories',
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      context.commit('setCategories', res.data);
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  memberFiles(context, params) {
    axios.get(REQUEST_URL + 'gp/user-group-files?id=' + params.id,
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      context.commit('setFiles', [res.data, params.id]);
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async addFile(context, params) {
    const formData = new FormData();
    
    formData.append('file', params.file);
    formData.append('group_id', params.group_id);
    formData.append('description', params.description);
    return axios.post(REQUEST_URL + "gp/user-group-files",
      formData,
      {
        headers: {
          'Authorization': context.rootState.auth.token,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        return true;
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        //console.log(error.config);
        return ({ 'error': error.message });
      });
  },
  async removeFile(context, params) {
    return axios.delete(REQUEST_URL + 'gp/user-group-files/' + params.id,
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(() => {
      //console.log(res.data);
      //context.commit('setGroup', res.data);
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async editFile(context, params) {
    //console.log('group/user-group-files/' + params.id, params);
    return axios.put(REQUEST_URL + 'gp/user-group-files/' + params.id, params,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then(async () => {
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  }, 
  memberSearch(context, params) {
    axios.get(REQUEST_URL + 'gp/user-group-member/search?id=' + params.id + '&name=' + params.name,
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      context.commit('setMembers', [res.data, params.id]);
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async add(context, params) {
    return axios.post(REQUEST_URL + 'gp/user-groups', 
    {
      name: params.name,
      description: params.description,
      category_id: params.category_id,
      private: params.private,
      search_visible: params.search_visible
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      context.commit('setUserGroup', res.data);
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async join(context, params) {
    return axios.post(REQUEST_URL + 'gp/user-group-members', 
    {
      id: params.id
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(async () => {
      // if(res.data.id != undefined){
      //  context.commit('setUpdateStatus', res.data);
      //}
      if(params.answers!=null){
        for(let i=0; i < params.answers.length; i++){
          let answer = params.answers[i];
          let question_id = params.questions[i].id;
          await context.dispatch('addAnswers', { id: question_id, answer: answer });
        }
      }
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async approve(context, params) {
    return axios.post(REQUEST_URL + 'gp/user-group-member/approve', 
    {
      id: params.id
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(async () => {
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async deny(context, params) {
    //console.log(params.id);
    return axios.post(REQUEST_URL + 'gp/user-group-member/deny', 
    {
      id: params.id,
      block: params.block,
      reason: params.reason
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(async () => {
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async adminToggle(context, params) {
    return axios.post(REQUEST_URL + 'gp/user-group-member/toggle-admin', 
    {
      group_id: params.group_id,
      user_id: params.user_id,
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(async () => {
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async isAdmin(context, params) {
    return axios.get(REQUEST_URL + 'gp/user-group-member/isadmin?id=' + params.group_id, 
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(async res => {
      return res.data;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async removeMember(context, params) {
    // storefront_id add in if needed
    //console.log('group/user-group-members/' + params.id + '?user=' + params.user_id + '&block=' + params.block);
    return axios.delete(REQUEST_URL + 'gp/user-group-members/' + params.id + '?user=' + params.user_id + '&block=' + params.block + '&reason=' + params.reason,
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(() => {
      //console.log(res.data);
      //context.commit('setGroup', res.data);
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async delete(context, params) {
    // storefront_id add in if needed
    return axios.delete(REQUEST_URL + 'gp/user-groups/' + params.id,
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(() => {
      //context.commit('setGroup', res.data);
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async edit(context, params) {
    return axios.put(REQUEST_URL + 'gp/user-groups/' + params.id, params,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then(async res => {
      context.commit('setGroup', res.data);
      if(params.questions!=null){
        // set the questions here
        await context.dispatch('addQuestions', {'questions': params.questions, 'group_id': params.id});
      }
      if(params.editQuestions!=null){
        for(let i=0; i < params.editQuestions.length; i++){
          let editQuestion = params.editQuestions[i];
          await context.dispatch('editQuestion', { id: editQuestion.id, question: editQuestion.question });
        }
      }
      context.dispatch('rules', { id: params.id });
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  }, 
  async addAnswers(context, params) {
    return axios.post(REQUEST_URL + 'gp/user-group-answers', 
    {
      id: params.id,
      answer: params.answer
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      if(res.data.id != undefined){
        context.commit('setQuestions', res.data);
      }
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async addQuestions(context, params) {
    return axios.post(REQUEST_URL + 'gp/user-group-questions', 
    {
      group_id: params.group_id,
      questions: params.questions
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token
      }
    })
    .then(res => {
      if(res.data.id != undefined){
        context.commit('setQuestions', res.data);
      }
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async deleteQuestion(context, params) {
     // storefront_id add in if needed
     return axios.delete(REQUEST_URL + 'gp/user-group-questions/' + params.id,
     {
       headers: {
         'Authorization': context.rootState.auth.token
       }
     })
     .then(() => {
       return true;
     })
     .catch(error => {
       return({'error': error.message + ' ' + REQUEST_URL});
     });
  },
  async editQuestion(context, params) {
    return axios.put(REQUEST_URL + 'gp/user-group-questions/' + params.id, params,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then(() => {
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  }, 
  async updatePhoto(context, params) {
    if (params.file != null) {
      // const blob = await fetch(base64).then(res => res.blob());
      const formData = new FormData();
      const photo = params.file;
      formData.append('id', params.id);

      if (params.type == "groupprofile") {
        formData.append('profile_file', photo);
      }
      if (params.type == "groupbanner") {
        formData.append('banner_file', photo);
      }
      return axios.post(REQUEST_URL + "gp/user-group/image-update",
        formData,
        {
          headers: {
            'Authorization': context.rootState.auth.token,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          context.commit('setUserGroup', res.data);
          // return res;
        })
        .catch(error => {
          return ({ 'error': error.message });
        });

    }
  },
}

// mutations
const mutations = {
  setGroup(state, response) {
    state.groupData = response;
  },
  setUserGroup(state, response) {
    state.userGroupData = response;
  },
  setUserGroups(state, response) {
    state.userGroupsData = response;
  },
  setAddUserGroups(state, response) {
    let existingGroupData = state.userGroupsData;
    let updatedArray = existingGroupData.concat(response);
    state.userGroupsData = updatedArray;
  },
  setFeaturedGroups(state, response) {
    state.featuredData = response;
  },
  setSectionGroups(state, response) {
    state.sectionData = response;
  },
  setMyGroups(state, response) {
    state.myGroupsData = response;
  },
  setAddMyGroups(state, response) {
    let existingGroupData = state.myGroupsData;
    let updatedArray = existingGroupData.concat(response);
    state.myGroupsData = updatedArray;
  },
  setCategories(state, response) {
    state.categoryData = response;
  },
  setGroupProfile(state, response){
    //console.log(response);
    if (response[0] != null) {
      state.groupProfile[response[0]] = response[1];
    }
    // state.groupProfile = response;
  },
  setGroupRules(state, response){
    state.groupRules = response;
  },
  /* setUpdateStatus(state, response){
    if(state.userGroupsData!=null){
      for(let i=0; i<state.userGroupsData.length; i++){
        if(state.userGroupsData[i].id==response.group_id){
          state.userGroupsData[i].isMember = true;
        }
      }
    }
    if(state.userGroupData.id==response.group_id){
      state.userGroupData.isMember = true;
    }
  }, */
  setQuestions(state, response){
    state.userGroupData.questions = response;
  },
  setMembers(state, response){
    let responseData = response[0];
    const groupId = response[1];
    state.membersData[groupId] = responseData;
  },
  setAddToMembers(state, response){
    let responseData = response[0];
    const groupId = response[1];
    if(response!=null){
      let existingFriends = state.membersData[groupId];
      let updatedArray = existingFriends.concat(responseData);
      state.membersData[groupId] = updatedArray;
    }else{
      state.membersData[groupId] = null;
    }
  },
  setRequests(state, response){
    state.joinRequestData = response;
  },
  setAnswers(state, response){
    state.userAnswers = response;
  },
  setBlocked(state, response){
    state.blockedData = response;
  },
  setFiles(state, response){
    let responseData = response[0];
    const groupId = response[1];
    state.filesData[groupId] = responseData;
  },
  logout(state){
    state.groupData = [];
    state.userGroupData =  [];
    state.userGroupsData =  null;
    state.myGroupsData =  null;
    state.categoryData =  null;
    state.groupProfile =  [];
    state.groupRules =  null;
    state.membersData =  [];
    state.joinRequestData =  null;
    state.userAnswers =  null;
    state.blockedData =  null;
    state.filesData =  [];
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
