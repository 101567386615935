// initial state
import getEnv from '@/utils/env'

const REQUEST_URL = getEnv('REQUEST_URL');

import axios from 'axios';

const state = () => ({
  responseData:[],
  exploreListsData:[],
  hashtagData:[],
  likeProfileData:[],
  commentProfileData:[],
  commentPostData:[],
  likePostData:[]
})

// getters
const getters = {}

// actions
const actions = {
  async getData(context, params) {
    var token = context.rootState.auth.anonToken;
    if(context.rootState.auth.token!=''){
      token = context.rootState.auth.token;
    }

    var url = 'explore/all';
    if(params.type!=null){
      url = 'explore/all?type=' + params.type;
    }
    return axios.get(REQUEST_URL + url,
    {
      headers: {
        'Authorization': token
      }
    })
    .then(res => {
      if(params.page == 1){
        context.commit('addToRequest', res.data);
      }else{
        context.commit('setRequest', res.data);
      }
      return res.data;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async hashtags(context) {
    var token = context.rootState.auth.anonToken;
    if(context.rootState.auth.token!=''){
      token = context.rootState.auth.token;
    }

    return axios.get(REQUEST_URL + 'explore/hashtags',
    {
      headers: {
        'Authorization': token
      }
    })
    .then(res => {
      context.commit('setHashtags', res.data);
      return true;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async topLikeProfiles(context) {
    var token = context.rootState.auth.anonToken;
    if(context.rootState.auth.token!=''){
      token = context.rootState.auth.token;
    }

    return axios.get(REQUEST_URL + 'explore/top-likes',
    {
      headers: {
        'Authorization': token
      }
    })
    .then(res => {
      context.commit('setLikeProfiles', res.data);
      return true;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async topCommentProfiles(context) {
    var token = context.rootState.auth.anonToken;
    if(context.rootState.auth.token!=''){
      token = context.rootState.auth.token;
    }

    return axios.get(REQUEST_URL + 'explore/top-comments',
    {
      headers: {
        'Authorization': token
      }
    })
    .then(res => {
      context.commit('setCommentProfiles', res.data);
      return true;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async topLikePosts(context) {
    var token = context.rootState.auth.anonToken;
    if(context.rootState.auth.token!=''){
      token = context.rootState.auth.token;
    }

    return axios.get(REQUEST_URL + 'explore/top-post-likes',
    {
      headers: {
        'Authorization': token
      }
    })
    .then(res => {
      context.commit('setLikePosts', res.data);
      return true;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async topCommentPosts(context) {
    var token = context.rootState.auth.anonToken;
    if(context.rootState.auth.token!=''){
      token = context.rootState.auth.token;
    }

    return axios.get(REQUEST_URL + 'explore/top-post-comments',
    {
      headers: {
        'Authorization': token
      }
    })
    .then(res => {
      context.commit('setCommentPosts', res.data);
      return true;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },

}

// mutations
const mutations = {
  setRequest(state, response) {
    state.responseData = response;
    if(response.lists!=null && response.lists.length>0){
      state.exploreListsData = response.lists;
    }
  },
  addToRequest(state, response) {
    var j = state.responseData['images'].length;
    const max = response['images'].length;
    const min = 0;
    for (let i = min; i < max; i++) {
      state.responseData['images'][j] = response['images'][i];
      j = j+1;
    }
  },
  setHashtags(state, response) {
    state.hashtagData = response;
  },
  setLikeProfiles(state, response) {
    state.likeProfileData = response;
  },
  setCommentProfiles(state, response) {
    state.commentProfileData = response;
  },
  setLikePosts(state, response) {
    state.likePostData = response;
  },
  setCommentPosts(state, response) {
    state.commentPostData = response;
  },
  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
