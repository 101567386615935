// initial state
import getEnv from '@/utils/env'

const REQUEST_URL = getEnv('REQUEST_URL');

import axios from 'axios';

const state = () => ({
  responseData:[],
})

// getters
const getters = {}

// actions
const actions = {
  async getData(context, params) {
    var token = context.rootState.auth.anonToken;
    if(context.rootState.auth.token!=''){
      token = context.rootState.auth.token;
    }

    var url = 'explore/featured-all' + "?currentPage=" + params.current_page + "&totalList=" + params.total_list;
    if(params.type!=null){
      url = 'explore/featured-all?type=' + params.type.id + "&currentPage=" + params.current_page + "&totalList=" + params.total_list;
    }
    return axios.get(REQUEST_URL + url,
    {
      headers: {
        'Authorization': token
      }
    })
    .then(res => {
      if(params.page == 1 && res.data.length!=0){
        context.commit('addToRequest', res.data);
      }else{
        context.commit('setRequest', res.data);
      }
      return res.data;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },

}

// mutations
const mutations = {
  setRequest(state, response) {
    state.responseData = response;
  },
  addToRequest(state, response) {
    var j = state.responseData['images'].length;
    const max = response['images'].length;
    const min = 0;
    for (let i = min; i < max; i++) {
      state.responseData['images'][j] = response['images'][i];
      j = j+1;
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
