<template>
  <ion-page>
    <ion-header :translucent="true">
      <HeaderContainer title="Feeds" />
      <ion-toolbar v-if="getEnv('APP_NAME') == 'Vocl'">
        <ion-segment v-model="feedSwitch" class="home-feed-col" @ionChange="changeSlide()" value="featured">
          <ion-segment-button value="featured">
            <ion-label>{{ $t("tabs.foryou") }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="elections">
            <ion-label>{{ $t("tabs.elections") }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="home">
            <ion-label>{{ $t("tabs.following") }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="groupfeed">
            <ion-label>{{ $t("tabs.groups") }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
      <ion-toolbar v-else>
        <ion-segment v-model="feedSwitch" class="home-feed-col" @ionChange="changeSlide()" value="featured">
          <ion-segment-button value="home">
            <ion-label>{{ $t("tabs.following") }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="featured">
            <ion-label>{{ $t("tabs.foryou") }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="groupfeed">
            <ion-label>{{ $t("tabs.groups") }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>
    <ion-content scrollY="false" ref="content" :fullscreen="true">
      <ion-refresher class="home-feed-col" slot="fixed" :disabled="notAtTop" @ionRefresh="doRefresh($event)">
        <ion-refresher-content :pulling-icon="chevronDownCircleOutline" pulling-text="Pull to refresh"
          refreshing-spinner="bubbles"></ion-refresher-content>
      </ion-refresher>
      <ion-grid scrollY="false" class="ion-no-padding">
        <ion-row>
          <ion-col scrollY="false" class="ion-no-padding home-feed-col">
            <swiper-container class="ion-content-scroll-host" scrollY="false" :autoheight="true" @swiperslidechangetransitionend="slideChanged()"
              ref="feedSwiper" no-swiping-selector=".vjs-control-bar">
              <swiper-slide>
                <FeedContainer @scroll.passive="handleScroll($event)" v-if="this.$store.state.auth.token != ''" streamName="featured" :stories="featuredfeed"
                  v-model:refreshFeed="refreshFeed" v-model:networkTimeout="timeout" />
              </swiper-slide>
              <swiper-slide>
                <FeedContainer @scroll.passive="handleScroll($event)" v-if="this.$store.state.auth.token != ''" streamName="elections" :stories="electionsfeed"
                  v-model:refreshFeed="refreshElectionFeed" v-model:networkTimeout="timeout" />
              </swiper-slide>
              <swiper-slide>
                <FeedContainer @scroll.passive="handleScroll($event)" v-if="this.$store.state.auth.token != ''" streamName="home" :stories="homefeed"
                  v-model:refreshFeed="refreshHomeFeed" v-model:networkTimeout="timeout" />
              </swiper-slide>
              <swiper-slide>
                <FeedContainer @scroll.passive="handleScroll($event)" v-if="this.$store.state.auth.token != ''" streamName="groupfeed" :stories="groupfeed"
                  v-model:refreshFeed="refreshGroupFeed" v-model:networkTimeout="timeout" />
              </swiper-slide>
            </swiper-container>       
          </ion-col>
          <ion-col class="ion-padding-horizontal sidebar">
            <div style="position:sticky; top: 15px; height: 85vh; overflow: scroll;">
              <div v-if="topLikeProfiles.length>0">
                <div style="margin:15px 5px;"><strong>Trending Profiles</strong></div>
                <div style="padding:0; margin:15px 5px;" class="story-header"
                  v-for="(friend, friendIndex) in topLikeProfiles.slice(0, 4)" :key="friendIndex">
                  <img style="cursor:pointer;" @click="selectFriend(friend)" class="post-profile-pic"
                    v-if="friend.profile_pic != '' && friend.profile_pic != undefined"
                    :src="profilepath + friend.profile_pic">
                  <img style="cursor:pointer;" @click="selectFriend(friend)" class="post-profile-pic"
                    src="/assets/spyguy.png" v-if="friend.profile_pic == '' || friend.profile_pic == undefined">
                  <div class="story-header-content" style="padding-left:0px;">
                    <div class="story-header-post" style="word-break: break-all; cursor:pointer;">
                      <span @click="selectFriend(friend)"
                        style="font-size:0.9em; font-weight: bold;  word-wrap:break-word; margin-right:5px;">{{ friend.username
                        }}</span>
                      <UserBadges :ecb="friend.ecb" :certified="friend.certified" />
                    </div>
                    <div @click="selectFriend(friend)" class="user-name-header" style="font-size:0.9em;">{{ friend.name
                      }}</div>
                  </div>
                </div>
              </div>
              <hr>
              <div v-if="topLikePosts.length>0">
                <div style="margin:15px 5px;"><strong>Trending Posts</strong></div>
                <ion-grid>
                  <ion-row style="cursor: pointer;" @click="selectStory(story)"
                    v-for="(story, storyIndex) in topLikePosts.slice(0, 5)" :key="storyIndex">
                    <ion-col size="7" class="ion-padding-bottom">
                      <span style="font-size: 0.9em; font-weight:bold;">{{ story.first_name }}</span><br />
                      <div class="caption-box-sm"><span style="font-size:0.9em" v-html="story.description"></span></div>
                    </ion-col>
                    <ion-col class="ion-padding-bottom">
                      <img style="float:right; cursor:pointer;" class="story-thumb-pic-md"
                        v-if="story.pik_name != '' && story.pik_name != undefined"
                        :src="path + '/piks/' + story.pik_name">
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>
              <hr>
              <div v-if="hashtagRecent!=undefined">
                <div style="margin:5px;"><strong>Trending Topics</strong></div>
                <div v-for="(hashtag, hashtagIndex) in hashtagRecent" :key="hashtagIndex"
                  @click="selectHashtag(hashtagIndex)">
                  <div style="cursor:pointer; word-break: break-all; margin: 15px 5px;">#{{ hashtagIndex }}</div>
                </div>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-fab id="posting-button" v-if="this.$store.state.helpers.postToggle == ''" vertical="bottom" horizontal="end"
        slot="fixed">
        <ion-fab-button @click="choosePostType">
          <ion-icon :icon="pencil"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <ion-fab id="posting-button" v-if="this.reporter == 1 && this.$store.state.helpers.postToggle == ''"
        vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button>
          <ion-icon :icon="pencil"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="start">
          <ion-fab-button color="primary" @click="openPostModal('post')">
            <ion-icon :icon="pencil"></ion-icon>
          </ion-fab-button>
          <ion-fab-button @click="openPostModal('ecb')">
            <img src="/assets/ecb_btn.png">
          </ion-fab-button>
        </ion-fab-list>
      </ion-fab>
      <ion-fab id="posting-button" v-if="this.reporter != 1 && this.$store.state.helpers.postToggle == ''"
        vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="openPostModal('post')">
          <ion-icon :icon="pencil"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>
<script>
import { IonContent, IonLabel, IonPage, modalController, IonFab, IonFabButton, IonIcon, IonSegment, IonSegmentButton, IonGrid, IonCol, IonRow, isPlatform, IonHeader, IonToolbar, actionSheetController, IonFabList, toastController, IonRefresher, IonRefresherContent } from '@ionic/vue';
import { defineAsyncComponent } from 'vue'
import getEnv from '@/utils/env'
import { pencil, chevronDownCircleOutline } from 'ionicons/icons';
import UserBadges from '@/components/UserBadges.vue';

const FeedContainer = defineAsyncComponent(() =>
  import('@/components/FeedContainer.vue'))

const PostModal = defineAsyncComponent(() =>
  import('@/components/posts/CreatePost.vue'))

const WhatsNewModal = defineAsyncComponent(() =>
  import('@/components/WhatsNewModal.vue'))

import HeaderContainer from '@/components/HeaderContainer.vue';
import { version, versionElectron, versionAndroid, versionApple } from '../../package'

/* const RightBarContainer = defineAsyncComponent ( () =>
import('@/components/RightBarContainer.vue'))*/

export default {
  name: 'TabView',
  components: {
    FeedContainer,
    IonContent,
    IonPage,
    IonFab,
    IonFabButton,
    IonIcon,
    HeaderContainer,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonGrid,
    IonCol,
    IonRow,
    IonHeader,
    IonToolbar,
    IonFabList,
    IonRefresher, 
    IonRefresherContent,
    UserBadges
  },
  setup() {
    return {
      getEnv,
      pencil,
      chevronDownCircleOutline
    }
  },
  data() {
    return {
      refreshFeed: false,
      refreshElectionFeed: false,
      refreshHomeFeed: false,
      refreshGroupFeed: false,
      timeout: false,
      report: false,
      feedSwitch: '',
      profilepath: getEnv('AWS_URL') + getEnv('PHOTO_BUCKET') + '/profile/',
      path: getEnv('AWS_URL') + getEnv('PHOTO_BUCKET'),
      thisVersion: version,
      notAtTop: false,
      featuredFeedPos: 0,
      electionFeedPos: 0,
      homeFeedPos: 0,
      groupFeedPos: 0, 
    }
  },
  async ionViewDidEnter() {
    this.$store.dispatch("header/general", {
      page: this.$t('header.home')
    });
    /* if(getEnv('APP_NAME') == 'Vocl'){
      this.feedSwitch = 'featured';
    }else{
      this.feedSwitch = 'home';
    }*/
    this.feedSwitch = 'home';
    await this.$refs.feedSwiper.swiper.slideTo(2);
    this.getNewsfeed();

    if (!isPlatform('iphone') && !isPlatform('android')) {
      this.$store.dispatch("explore/hashtags");
      this.$store.dispatch("explore/topLikeProfiles");
      this.$store.dispatch("explore/topLikePosts");
    }

    
    if (isPlatform('hybrid') && isPlatform('ios')) {
      this.thisVersion = versionApple;
    } else if (isPlatform('hybrid') && isPlatform('android')) {
      this.thisVersion = versionAndroid;
    } else if (isPlatform('electron')) {
      this.thisVersion = versionElectron;
    }
    //console.log(this.thisVersion, 'this version');
    //console.log(this.$store.state.profile.shownew, 'new announce');
    //this.getNewsfeed();

    if(this.$store.state.profile.shownew!=this.thisVersion){
      this.openWhatsNewModal();
    }
  },
  methods: {
    async choosePostType() {
      if (this.reporter == 1) {
        const actionSheet = await actionSheetController.create({
          header: this.$t('modalhead.createpost'),
          buttons: [
            {
              text: this.$t('buttons.reportPost'),
              data: {
                action: 'post',
              },
            },
            {
              text: this.$t('buttons.reportElection'),
              data: {
                action: 'report',
              },
            },
            {
              text: this.$t('buttons.cancel'),
              role: 'cancel',
              data: {
                action: 'cancel',
              },
            },
          ],
        });

        await actionSheet.present();
        let dismissed = await actionSheet.onDidDismiss();
        if (dismissed != undefined) {
          if (dismissed.data.action != 'cancel') {
            if (dismissed.data.action == 'report') {
              await this.openPostModal('ecb');
            }
          }
          //console.log(dismissed.data, 'data');
        }
      } else {
        this.openPostModal('post');
      }

    },
    async openPostModal(type) {
      if (this.$store.state.auth.token != '') {
        let title = this.$t('modaltitle.createpost');
        this.report = false;
        
        if (type == 'ecb') {
          this.report = true;
          title = this.$t('buttons.reportElection');
        }
        const modal = await modalController
          .create({
            component: PostModal,
            cssClass: 'modal-custom-class',
            componentProps: {
              title: title,
              share_link: '',
              report: this.report
            }
          })
        this.refreshFeed = false;
        await modal.present();

        const dismissed = await modal.onDidDismiss();
        if (dismissed.role != 'canceled') {
          this.report = false;
          if(dismissed.role=='groups'){
            this.presentToast('top', 'Your group post was sent!', dismissed.data.id)
            if(this.feedSwitch=='groupfeed'){
              this.refreshGroupFeed = true;
            }
          }else if(dismissed.role=='elections'){
            this.presentToast('top', 'Your election post was sent!', dismissed.data.id)
            if(this.feedSwitch=='elections'){
              this.refreshElectionFeed = true;
            }
          }else{
            if(this.feedSwitch=='home'){
              this.refreshHomeFeed = true;
            }
            this.presentToast('top', 'Your post was sent!', dismissed.data.id)
          }           
        }
      }
    },
    async presentToast(position, message, image_id) {
      const toastButtons = [
        {
          text: 'View Post',
          role: 'info',
          handler: () => {
            console.log('More Info clicked');
            this.$router.push({ name: 'story', params: { imageid: image_id } });
          },
        },
        {
          text: 'Dismiss',
          role: 'cancel',
          handler: () => {
            console.log('Dismiss clicked');
          },
        },
      ];
      const toast = await toastController.create({
        message: message,
        duration: 7000,
        position: position,
        buttons: toastButtons,
        color: 'primary'
      });

      await toast.present();
    },
    async openWhatsNewModal() {
      if (this.$store.state.auth.token != '') {
        const modal = await modalController
          .create({
            component: WhatsNewModal,
            cssClass: 'modal-custom-class',
            componentProps: {
              title: 'What\'s New',
            }
          })
        this.refreshFeed = false;
        await modal.present();

        const dismissed = await modal.onDidDismiss();
        if (dismissed != undefined) {
          // set the shownew to the current version of the app
          this.$store.commit('profile/setShowWhatsNew', this.thisVersion);
        }
      }
    },
    /* showWelcome() {
      let showIt = false;
      if (this.$store.state.auth.token != '' && getEnv('INFLUENCER_FEED') == 0) {
        if (this.$store.state.pikstream.responseData != null && this.$store.state.pikstream.responseData.length == 0) {
          showIt = true;
        }
      }
      return showIt;
    }, */
    selectFriend(friend) {
      if (friend.userid != null) {
        this.$router.push({ name: 'profile', params: { id: friend.userid } });
      } else if (friend.user_name != null) {
        this.$router.push({ name: 'username', params: { username: friend.user_name } });
      }
    },
    selectStory(story) {
      this.$router.push({ name: 'story', params: { imageid: story.image_id } });
    },
    selectHashtag(hashtag) {
      var hashtagString = hashtag.replace('#', '');
      this.$store.dispatch("helpers/hashtagPosts", {
        search: hashtagString,
        totalList: 50,
        currentPage: 0,
        update: false
      });
      this.$router.push({ name: 'search', params: { id: hashtag.toLowerCase() } });
    },
    async changeSlide(){
      //('changeSlide', this.feedSwitch);
      if (this.$refs.feedSwiper.swiper != undefined) {
        switch (this.feedSwitch) {
          case 'featured':
            await this.$refs.feedSwiper.swiper.slideTo(0);
            break;
          case 'elections':
            await this.$refs.feedSwiper.swiper.slideTo(1);
            break;
          case 'home':
            await this.$refs.feedSwiper.swiper.slideTo(2);
            break;
          case 'groupfeed':
            await this.$refs.feedSwiper.swiper.slideTo(3);
            break;
          default:
            break;
        }
      }
    },
    async slideChanged(){
      //console.log(this.$refs.feedSwiper.swiper.activeIndex);
      if (this.$refs.feedSwiper.swiper != undefined) {
        let swiperIndex = this.$refs.feedSwiper.swiper.activeIndex;
        switch (swiperIndex) {
          case 0:
            this.feedSwitch = 'featured';
            break;
          case 1:
            this.feedSwitch = 'elections';
            break;
          case 2:
            this.feedSwitch = 'home';
            break;
          case 3:
            this.feedSwitch = 'groupfeed';
            break;

          default:
            break;
        }
      }
      await this.getNewsfeed();

    },
    async getNewsfeed(){
      //console.log(this.$refs.feedSwiper.swiper);
      /* let setFeaturedReload = false;
      let setElectionsReload = false;
      let setHomeReload = false;
      let setGroupsReload = false;
      
      if (this.$store.state.helpers.reloadStream.includes('featured')) {
        setFeaturedReload = true;
      }
      if (this.$store.state.helpers.reloadStream.includes('elections')) {
        setElectionsReload = true;
      }
      if (this.$store.state.helpers.reloadStream.includes('home')) {
        setHomeReload = true;
      }
      if (this.$store.state.helpers.reloadStream.includes('groups')) {
        setGroupsReload = true;
      } */

      if (this.feedSwitch == 'featured') {
        if (this.$store.state.pikstream.recommendResponseData == null) {
          //console.log('featured load');
          let response = await this.$store.dispatch("pikstream/getStream", {
            streamName: 'featured',
            filter: '',
            imageid: '',
            storyid: '',
            total_list: this.$store.state.pikstream.totalList.recommend,
            last_id: this.$store.state.pikstream.lastId.recommend,
            userid: ''
          });
          if(response!=undefined){
            //this.$store.commit("helpers/setReloadStream", {type: "featured", switch: "off"});
            if(response.error != undefined){
              console.log('there was an error1', response.error);
              this.timeout = true;
            }
          }
        }
      }else if (this.feedSwitch == 'home') {
        //this.$refs.feedSwiper.swiper.slideTo(2);
        if (this.$store.state.pikstream.followResponseData == null) {
          //console.log('home load');
          let response = await this.$store.dispatch("pikstream/getStream", {
            streamName: 'home',
            filter: '',
            imageid: '',
            storyid: '',
            total_list: this.$store.state.pikstream.totalList.follow,
            last_id: this.$store.state.pikstream.lastId.follow,
            userid: ''
          });
          if(response!=undefined){
            //this.$store.commit("helpers/setReloadStream", {type: "home", switch: "off"});
            if(response.error != undefined){
              console.log('there was an error 2', response.error);
              this.timeout = true;
            }
          }
        } 
        
      } else if (this.feedSwitch == 'groupfeed') {
        if (this.$store.state.pikstream.groupFeedResponseData == null) {
          //console.log('groupfeed load');   
          let response = await this.$store.dispatch("pikstream/getStream", {
            streamName: 'groupfeed',
            filter: '',
            imageid: '',
            storyid: '',
            total_list: this.$store.state.pikstream.totalList.groupfeed,
            last_id: this.$store.state.pikstream.lastId.groupfeed,
            userid: ''
          });
          if(response!=undefined){
            //this.$store.commit("helpers/setReloadStream", {type: "groups", switch: "off"});
            if(response.error != undefined){
              console.log('there was an error3', response.error);
              this.timeout = true;
            }
          }
        }
      } else if (this.feedSwitch == 'elections') {
        if (this.$store.state.pikstream.electionResponseData == null) {
          //console.log('elections load');   
          let response = await this.$store.dispatch("pikstream/getStream", {
            streamName: 'elections',
            filter: '',
            imageid: '',
            storyid: '',
            total_list: this.$store.state.pikstream.totalList.election,
            last_id: this.$store.state.pikstream.lastId.election,
            userid: ''
          });
          if(response!=undefined){
            //this.$store.commit("helpers/setReloadStream", {type: "elections", switch: "off"});
            if(response.error != undefined){
              console.log('there was an error4', response.error);
              this.timeout = true;
            }
          }
        } 
      }
    },
    async doRefresh(event) {
      //console.log('do refresh', this.feedSwitch);
      if (this.feedSwitch == "home") {
        this.refreshHomeFeed = true;
      } else if (this.feedSwitch == "featured") {
        this.refreshFeed = true;
      } else if (this.feedSwitch == "elections") {
        this.refreshElectionFeed = true;
      } else if (this.feedSwitch == "groupfeed") {
        this.refreshGroupFeed = true;
      }
      setTimeout(() => {
        event.target.complete();
      }, 500); 
    },
    handleScroll (e) {
      var scrollPos = e.target.scrollTop;
      this.notAtTop = true;
      if (this.feedSwitch == "home") {
        this.homeFeedPos = scrollPos;
        if(this.homeFeedPos < 40){
          this.notAtTop = false;
        }
      } else if (this.feedSwitch == "featured") {
        this.featuredFeedPos = scrollPos;
        if(this.featuredFeedPos < 40){
          this.notAtTop = false;
        }
      } else if (this.feedSwitch == "elections") {
        this.electionFeedPos = scrollPos;
        if(this.electionFeedPos < 40){
          this.notAtTop = false;
        }
      } else if (this.feedSwitch == "groupfeed") {
        this.groupFeedPos = scrollPos;
        if(this.groupFeedPos < 40){
          this.notAtTop = false;
        }
      }
      //console.log(scrollPos);
    }
  },
  computed: {
    featuredfeed() {
      if (this.$store.state.pikstream.recommendResponseData != null) {
        return this.$store.state.pikstream.recommendResponseData;
      }
      return [];
    },
    homefeed() {
      if (this.$store.state.pikstream.followResponseData != null) {
        return this.$store.state.pikstream.followResponseData;
      }
      return [];
    },
    electionsfeed() {
      if (this.$store.state.pikstream.electionResponseData != null) {
        return this.$store.state.pikstream.electionResponseData;
      }
      return [];
    },
    groupfeed() {
      if (this.$store.state.pikstream.groupFeedResponseData != null) {
        return this.$store.state.pikstream.groupFeedResponseData;
      }
      return [];
    },
    /* electionFeed() {
        if (this.$store.state.pikstream.electionResponseData == null) {
          this.$store.dispatch("pikstream/getStream", {
            streamName: 'elections',
            filter: '',
            imageid: '',
            storyid: '',
            total_list: this.$store.state.pikstream.totalList.election,
            last_id: this.$store.state.pikstream.lastId.election,
            userid: ''
          });
          return [];
        } else {
          return this.$store.state.pikstream.electionResponseData;
        }
    }, */
    reporter() {
      return this.$store.state.profile.reporter;
    },
    hashtagRecent() {
      return this.$store.state.explore.hashtagData;
    },
    topLikeProfiles() {
      return this.$store.state.explore.likeProfileData;
    },
    topLikePosts() {
      return this.$store.state.explore.likePostData;
    }
  }

}
</script>
<style scoped>
@media only screen and (min-width: 992px) {
  .showMobile {
    display: none;
  }
}

.ion-content-scroll-host::before,
.ion-content-scroll-host::after {
  position: absolute;
  
  width: 1px;
  height: 1px;
  
  content: "";
}

.ion-content-scroll-host::before {
  bottom: -1px;
}

.ion-content-scroll-host::after {
  top: -1px;
}
</style>
