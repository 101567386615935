<template>
  <ion-split-pane ref="split-pane" :when="showMenu()" content-id="main">
    <!--  the side menu  -->
    <ion-menu style="background-color:white;" content-id="main">
      <ion-header :translucent="true">
        <ion-toolbar class="side-bar-menu" color="light">
          <div slot="start" style="cursor:pointer; padding-left:24px;" @click="menuNavigation('/home')">
            <img v-if="this.darkMode=='true'" srcset="/assets/icon/logo_dark.png 1x, /assets/icon/logo_dark@2x.png 2x, /assets/icon/logo_dark@3x.png 3x" />
            <img v-else srcset="/assets/icon/logo_light.png 1x, /assets/icon/logo_light@2x.png 2x, /assets/icon/logo_light@3x.png 3x"/>
          </div>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-list class="nav-item side-bar-menu" lines="none">
          <div v-if="getEnv('APP_NAME') == 'Vocl'">
            <ion-item button class="nav-item" detail="false"
              @click="menuNavigation('/home')" id="tab-frank">
              <ion-icon :color="buttonColor(1)" :src="customButton(1)" slot="start" />
              <ion-label :color="buttonColor(1)">{{ $t("tabs.home") }}</ion-label>
            </ion-item>
            <ion-item button class="nav-item" detail="false"
              @click="menuNavigation('/elections')" id="tab-elections">
              <ion-icon :color="buttonColor(13)" :src="customButton(2)" slot="start"></ion-icon>
              <ion-label :color="buttonColor(13)">CoA</ion-label>
            </ion-item>
            <ion-item button class="nav-item" detail="false"
              @click="menuNavigation('/groups')" id="tab-groups">
              <ion-icon :color="buttonColor(14)" :icon="peopleCircle" slot="start"></ion-icon>
              <ion-label :color="buttonColor(14)">{{ $t("tabs.groups") }}</ion-label>
            </ion-item>
            <ion-item button class="nav-item" detail="false"
              @click="menuNavigation('/featured')" id="tab-featured">
              <ion-icon :color="buttonColor(6)" :icon="idCard" slot="start"></ion-icon>
              <ion-label :color="buttonColor(6)">{{ $t("tabs.featured") }}</ion-label>
            </ion-item>
            <ion-item button class="nav-item" detail="false"
              @click="menuNavigation('/explore')" id="tab-explore">
              <ion-icon :color="buttonColor(7)" :icon="compass" slot="start"></ion-icon>
              <ion-label :color="buttonColor(7)">{{ $t("tabs.explore") }}</ion-label>
            </ion-item>
            <ion-item button class="nav-item" detail="false" @click="menuNavigation('/saved')" id="tab-favorite">
              <ion-icon :color="buttonColor(5)" :icon="bookmarks" slot="start"></ion-icon>
              <ion-label :color="buttonColor(5)">{{ $t("tabs.saved") }}</ion-label>
            </ion-item>
            
            <ion-item button class="nav-item" detail="false" @click="menuNavigation('/messages')" id="tab-messages">
              <ion-icon :color="buttonColor(12)" :icon="chatbubble" slot="start"></ion-icon>
              <ion-label :color="buttonColor(12)">{{ $t("tabs.messages") }}</ion-label>
              <ion-badge color="danger">
                {{ this.$store.state.helpers.messageBadges }}
              </ion-badge>
            </ion-item>
            <ion-item button v-if="this.$store.state.auth.token" class="nav-item" detail="false"
              @click="goToNotifications()" id="tab-notifications">
              <ion-icon :color="buttonColor(10)" :icon="notifications" slot="start"></ion-icon>
              <ion-label :color="buttonColor(10)">{{ $t("tabs.notifications") }}</ion-label>
              <ion-badge color="danger">
                {{ this.$store.state.helpers.badges }}
              </ion-badge>
            </ion-item>
            <ion-item v-if="this.$store.state.auth.token" button class="nav-item" detail="false" @click="goToProfile()"
              id="tab-profile">
              <ion-icon :color="buttonColor(3)" :icon="personCircle" slot="start"></ion-icon>
              <ion-label :color="buttonColor(3)">{{ $t("tabs.profile") }}</ion-label>
            </ion-item>
            <ion-item button class="nav-item" detail="false" @click="menuNavigation('/friends/2/0')" id="tab-following">
              <ion-icon :color="buttonColor(4)" :icon="people" slot="start"></ion-icon>
              <ion-label :color="buttonColor(4)">{{ $t("tabs.following") }}</ion-label>
            </ion-item>
          </div>
          <div v-if="getEnv('APP_NAME') == 'ChoiceSocial'">
            <ion-item button class="nav-item" detail="false"
              @click="menuNavigation('/home')" id="tab-home">
              <ion-icon :color="buttonColor(1)" :icon="home" slot="start" />
              <ion-label :color="buttonColor(1)">{{ $t("tabs.home") }}</ion-label>
            </ion-item>
            <ion-item button class="nav-item" detail="false"
              @click="menuNavigation('/groups')" id="tab-groups">
              <ion-icon :color="buttonColor(14)" :icon="peopleCircle" slot="start"></ion-icon>
              <ion-label :color="buttonColor(14)">{{ $t("tabs.groups") }}</ion-label>
            </ion-item>
            <ion-item button class="nav-item" detail="false"
              @click="menuNavigation('/featured')" id="tab-featured">
              <ion-icon :color="buttonColor(6)" :icon="idCard" slot="start"></ion-icon>
              <ion-label :color="buttonColor(6)">{{ $t("tabs.featured") }}</ion-label>
            </ion-item>
            <ion-item button class="nav-item" detail="false"
              @click="menuNavigation('/explore')" id="tab-explore">
              <ion-icon :color="buttonColor(7)" :icon="globe" slot="start"></ion-icon>
              <ion-label :color="buttonColor(7)">{{ $t("tabs.explore") }}</ion-label>
            </ion-item>
            <ion-item button class="nav-item" detail="false" @click="menuNavigation('/saved')" id="tab-favorite">
              <ion-icon :color="buttonColor(5)" :icon="bookmarks" slot="start"></ion-icon>
              <ion-label :color="buttonColor(5)">{{ $t("tabs.saved") }}</ion-label>
            </ion-item>
            <ion-item button class="nav-item" detail="false" @click="menuNavigation('/messages')" id="tab-messages">
              <ion-icon :color="buttonColor(12)" :icon="chatbubble" slot="start"></ion-icon>
              <ion-label :color="buttonColor(12)">{{ $t("tabs.messages") }}</ion-label>
              <ion-badge color="danger">
                {{ this.$store.state.helpers.messageBadges }}
              </ion-badge>
            </ion-item>
            <ion-item button v-if="this.$store.state.auth.token" class="nav-item" detail="false"
              @click="goToNotifications()" id="tab-notifications">
              <ion-icon :color="buttonColor(10)" :icon="notifications" slot="start"></ion-icon>
              <ion-label :color="buttonColor(10)">{{ $t("tabs.notifications") }}</ion-label>
              <ion-badge color="danger">
                {{ this.$store.state.helpers.badges }}
              </ion-badge>
            </ion-item>
            <ion-item v-if="this.$store.state.auth.token" button class="nav-item" detail="false" @click="goToProfile()"
              id="tab-profile">
              <ion-icon :color="buttonColor(3)" :icon="personCircle" slot="start"></ion-icon>
              <ion-label :color="buttonColor(3)">{{ $t("tabs.profile") }}</ion-label>
            </ion-item>
            <ion-item button class="nav-item" detail="false" @click="menuNavigation('/friends/2/0')" id="tab-following">
              <ion-icon :color="buttonColor(4)" :icon="people" slot="start"></ion-icon>
              <ion-label :color="buttonColor(4)">{{ $t("tabs.following") }}</ion-label>
            </ion-item>
          </div>
          <ion-item-divider>
            <ion-label> {{ $t("tabs.manage") }} </ion-label>
          </ion-item-divider>
          <ion-item button class="nav-item" detail="false" @click="menuNavigation('/options')" id="tab-options">
            <ion-icon :color="buttonColor(9)" :icon="cog" slot="start"></ion-icon>
            <ion-label :color="buttonColor(9)">{{ $t("tabs.options") }}</ion-label>
          </ion-item>
          <ion-item class="nav-item" detail="false" button :href="getEnv('HELP_LINK')" target="_blank">
            <ion-icon :icon="helpCircle" slot="start"></ion-icon>
            <ion-label>{{ $t("tabs.howto") }}</ion-label>
          </ion-item>
          <ion-item class="nav-item" detail="false" button @click="openFeedbackModal()">
            <ion-icon :icon="send" slot="start"></ion-icon>
            <ion-label>{{ $t("tabs.feedback") }}</ion-label>
          </ion-item>
          <!--<ion-item @click="presentComingSoon()" button>
                    <ion-icon :icon="swapHorizontal" slot="start"></ion-icon>
                    <ion-label>Switch Account</ion-label>
                </ion-item>-->
          <ion-item class="nav-item" detail="false" lines="none">
            <ion-icon slot="start" :icon="moon"></ion-icon>
            <ion-toggle @ionChange="darkModeToggle" :checked="darkMode" id="themeToggle">{{ $t("tabs.toggle") }}</ion-toggle>
          </ion-item>
          <ion-item-divider v-if="getEnv('STORE_LINK') != '' || getEnv('LINK_1') != ''">
            <ion-label> {{ $t("tabs.links") }} </ion-label>
          </ion-item-divider>
          <ion-item v-if="getEnv('LINK_1') != '' && getEnv('LINK_1') != undefined" button class="nav-item" detail="false"
            :href="getEnv('LINK_1')" target="_blank">
            <ion-icon :src="getEnv('LINK_IMAGE_1')" slot="start" />
            <ion-label><p>{{ getEnv('LINK_LABEL_1') }}</p></ion-label>
          </ion-item>
          <ion-item v-if="getEnv('LINK_2') != '' && getEnv('LINK_2') != undefined" button class="nav-item" detail="false"
            :href="getEnv('LINK_2')" target="_blank">
            <img :src="getEnv('LINK_IMAGE_2')" slot="start">
            <ion-label><p>{{ getEnv('LINK_LABEL_2') }}</p></ion-label>
          </ion-item>
          <ion-item v-if="getEnv('LINK_3') != '' && getEnv('LINK_3') != undefined" button class="nav-item" detail="false"
            :href="getEnv('LINK_3')" target="_blank">
            <img :src="getEnv('LINK_IMAGE_3')" slot="start">
            <ion-label><p>{{ getEnv('LINK_LABEL_3') }}</p></ion-label>
          </ion-item>
          <ion-item-divider>{{ $t("tabs.logout") }}</ion-item-divider>
          <ion-item v-if="this.$store.state.auth.token != ''" class="nav-item" detail="false" button @click="logout">
            <ion-icon :icon="logOut" slot="start"></ion-icon>
            <ion-label>{{ $t("tabs.logout") }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>
    <!-- the main content -->
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</template>

<script>
import {
  IonList,
  IonRouterOutlet,
  IonContent,
  IonItem,
  IonMenu,
  IonSplitPane,
  IonToolbar,
  IonImg,
  IonHeader,
  IonLabel,
  IonIcon,
  IonItemDivider,
  IonToggle,
  IonBadge,
  modalController,
  alertController,
  isPlatform
} from "@ionic/vue";
import {
  images,
  menu,
  globe,
  personCircle,
  people,
  logOut,
  cog,
  helpCircle,
  send,
  swapHorizontal,
  moon,
  bookmarks,
  peopleCircle,
  idCard,
  home,
  cart,
  search,
  notifications,
  arrowBack,
  chatbubble,
  compass
} from "ionicons/icons";

import FeedbackModal from "@/components/SendFeedback.vue";
//import HowToModal from '@/components/HowToContainer.vue'
import { defineComponent } from 'vue'
import getEnv from "@/utils/env";
import { PushNotifications } from '@capacitor/push-notifications';
import SignupModal from '@/components/SignupModal.vue'
import { Browser } from '@capacitor/browser';

export default defineComponent({
  name: "SplitPane",
  components: {
    IonMenu,
    IonSplitPane,
    IonToolbar,
    IonHeader,
    IonLabel,
    IonIcon,
    IonList,
    IonItem,
    IonItemDivider,
    IonToggle,
    IonContent,
    IonRouterOutlet,
    IonBadge,
    IonImg
  },
  setup() {
    return {
      images,
      menu,
      globe,
      personCircle,
      people,
      logOut,
      cog,
      helpCircle,
      send,
      swapHorizontal,
      moon,
      bookmarks,
      peopleCircle,
      idCard,
      home,
      cart,
      getEnv,
      search,
      notifications,
      arrowBack,
      chatbubble,
      compass
    };
  },
  data() {
    return {
      profilepath: getEnv('AWS_URL') + getEnv('PHOTO_BUCKET') + '/profile/',
      pollData: null
    };
  },
  head() {
    return {
      title: this.$store.state.header.title,
      meta: this.$store.state.header.meta,
      script: [
        { src: 'https://' + getEnv('FATHOM_URL') + '/script.js', 'data-spa': 'auto', 'data-site': getEnv('FATHOM_ID') },
      ],
    }
  },
  mounted() {
    if (this.darkMode == "true") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
    if (this.$store.state.auth.token) {
      clearInterval(this.pollData); //clear out the old one
      this.beginPolling();
    }
  },
  methods: {
    menuNavigation(url) {
      if (!this.$store.state.auth.token) {
        //console.log("show login popup");
        this.presentLoginAlertConfirm();
        //this.$router.push('/login');
      } else {
        // check if you tapped the home button again
        var keyFlag = "";
        if (this.$route.path == url) {
          if (this.$route.path == "/saved") {
            keyFlag = "saved";
          } else if (this.$route.path == "/home") {
            keyFlag = "home";
          } else if (this.$route.path == "/explore") {
            keyFlag = "explore";
          } else if (this.$route.path == "/featured") {
            keyFlag = "featured";
          } else if (this.$route.path == "/elections") {
            keyFlag = "elections";
          } else if (this.$route.path == "/messages") {
            keyFlag = "messages";
          } else if (this.$route.path == "/groups") {
            keyFlag = "groups";
          } /* else if (this.$route.path == "/myprofile") {
            keyFlag = "myprofile";
          }*/
        }
        //console.log(keyFlag, 'keyFlag');
        //console.log(url, 'url');
        //console.log(this.$route.path + ' ' + url);
        if (keyFlag != "") {
          //console.log('going to the same place');
          //var streamType = "";
          /*if (keyFlag == "myprofile") {
            streamType = "myprofile";
            this.$store.commit("pikstream/setLastId", 0);
            this.$store.commit("pikstream/setTotalList", 7);
            this.$store.commit("pikstream/setMyProfileRequest", null);
          } else*/
          if (keyFlag == "saved") {
            this.$store.commit("pikstream/setFavoriteRequest", null);
            this.$store.dispatch("pikstream/getStream", {
              streamName: 'favorites',
              filter: '',
              imageid: '',
              storyid: '',
              total_list: this.$store.state.pikstream.totalList.favorite,
              last_id: this.$store.state.pikstream.lastId.favorite,
              userid: ''
            });
          } else if (keyFlag == "home") {
            this.$store.commit("pikstream/setFollowRequest", null);
            this.$store.dispatch("pikstream/getStream", {
              streamName: 'home',
              filter: '',
              imageid: '',
              storyid: '',
              total_list: this.$store.state.pikstream.totalList.follow,
              last_id: this.$store.state.pikstream.lastId.follow,
              userid: ''
            });

            /* this.$store.commit("helpers/setReloadStream", {type: "home", switch: "on"});
            this.$store.commit("helpers/setReloadStream", {type: "featured", switch: "on"});
            this.$store.commit("helpers/setReloadStream", {type: "elections", switch: "on"});
            this.$store.commit("helpers/setReloadStream", {type: "groups", switch: "on"}); */
      

            /* if(getEnv('APP_NAME') == 'Vocl'){
              this.$store.commit("pikstream/setRecommendRequest", null);
              this.$store.dispatch("pikstream/getStream", {
                streamName: 'featured',
                filter: '',
                imageid: '',
                storyid: '',
                total_list: this.$store.state.pikstream.totalList.recommend,
                last_id: this.$store.state.pikstream.lastId.recommend,
                userid: ''
              });
            }else{
              this.$store.commit("pikstream/setFollowRequest", null);
              this.$store.dispatch("pikstream/getStream", {
                streamName: 'home',
                filter: '',
                imageid: '',
                storyid: '',
                total_list: this.$store.state.pikstream.totalList.follow,
                last_id: this.$store.state.pikstream.lastId.follow,
                userid: ''
              });
            }
            this.$store.commit('pikstream/setRecommendRequest', null);
            this.$store.commit('pikstream/setElectionRequest', null);
            this.$store.commit('pikstream/setGroupFeedRequestNull');
            this.$store.commit("pikstream/setFollowRequest", null);
            
            */
          } else if (keyFlag == "explore") {
            this.$store.commit("explore/setRequest", []);
            this.$store.dispatch("explore/getData", {
              page: 0,
              type: null,
            });
          } else if (keyFlag == "featured") {
            this.$store.commit("featured/setRequest", []);
            this.$store.dispatch("featured/getData", {
              page: 0,
              type: null,
              current_page: 0,
              total_list: 30,
            });
          } else if (keyFlag == "elections") {
            this.$store.commit("elections/setRequest", []);
            this.$store.dispatch("elections/getData", {
                page: 0,
                type: '',
                current_page: 0,
                total_list: 30,
            });
          } else if (keyFlag == "messages") {
            this.$store.commit("messages/setThreads", []);
            this.$store.dispatch("messages/allThreads");
          } else if (keyFlag == "groups") {
            this.$store.commit("groups/setUserGroups", null);
            this.$store.commit("groups/setFeaturedGroups", null);
            this.$store.commit("groups/setSectionGroups", null);
          }

        }
        let headerValue = this.getHeaderName(url);
        this.$store.dispatch("header/general", {
          page: headerValue
        });

        if (this.$store.state.auth.token != '') {
          this.$store.dispatch("helpers/getNotifyBadges");
        }

        this.$store.commit("helpers/setToggleMenu", false);
        this.$router.replace({ path: url });

      }
    },
    getHeaderName(url) {
      let title = "";
      if (url == "/saved") {
        title = this.$t("header.saved");
      } else if (url == "/home") {
        title = this.$t("header.home");
      } else if (url == "/explore") {
        title = this.$t("header.explore");
      } else if (url == "/featured") {
        title = this.$t("header.featured");
      } else if (url == "/friends/2/0" || url == '/followers') {
        title = this.$t("header.followers");
      } else if (url == "/friends/1/0" || url == '/following') {
        title = this.$t("header.following");
      } else if (url == "/options") {
        title = this.$t("header.options");
      } else if (url == "/notifications") {
        title = this.$t("header.notifications");
      } else if (url == "/user-login") {
        title = this.$t("header.login");
      } else if (url == "/signup") {
        title = this.$t("header.signup");
      } else if (url == "/messages") {
        title = this.$t("header.messages");
      } else if (url == "/elections") {
        title = this.$t("header.elections");
      }
      return title;
    },
    tabColor(index) {
      if (index == 1 && this.$route.path == "/home") {
        return "light";
      } else if (index == 3 && this.$route.path == "/profile/" + this.myProfileId) {
        return "light";
      } else if (index == 4 && this.$route.path == "/friends/2/0") {
        return "light";
      } else if (index == 5 && this.$route.path == "/saved") {
        return "light";
      } else if (
        index == 6 &&
        (this.$route.path == "/featured" ||
          this.$route.path == "/premium")
      ) {
        return "light";
      } else if (index == 7 && this.$route.path == "/explore") {
        return "light";
      } else if (index == 8 && this.$route.path == "/groups") {
        return "light";
      } else if (index == 9 && this.$route.path == "/options") {
        return "light";
      } else if (index == 10 && this.$route.path == "/notifications") {
        return "light";
      }
    },
    buttonColor(index) {
      if (index == 1 && this.$route.path == "/home") {
        return "primary";
      } else if (index == 3 && this.$route.path == "/profile/" + this.myProfileId) {
        return "primary";
      } else if (index == 4 && this.$route.path == "/friends/2/0") {
        return "primary";
      } else if (index == 5 && this.$route.path == "/saved") {
        return "primary";
      } else if (
        index == 6 &&
        (this.$route.path == "/featured" ||
          this.$route.path == "/premium")
      ) {
        return "primary";
      } else if (index == 7 && this.$route.path == "/explore") {
        return "primary";
      } else if (index == 8 && this.$route.path == "/groups") {
        return "primary";
      } else if (index == 9 && this.$route.path == "/options") {
        return "primary";
      } else if (index == 10 && this.$route.path == "/notifications") {
        return "primary";
      } else if (index == 11 && this.$route.path == "/menu") {
        return "primary";
      } else if (index == 12 && (this.$route.name == "messages" || this.$route.name == "message-thread")) {
        return "primary";
      } else if (index == 13 && (this.$route.name == "elections")) {
        return "primary";
      } else if (index == 14 && (this.$route.name == "groups")) {
        return "primary";
      }

    },
    displayBackButton() {
      var isSubPage = true;
      if ((this.$route.path == "/featured" ||
        this.$route.path == "/home") ||
        this.$route.path == "/explore" ||
        this.$route.path == "/searchall" ||
        this.$route.path == "/friends/2/0" ||
        this.$route.path == "/profile/" + this.myProfileId ||
        this.$route.path == "/saved" ||
        this.$route.path == "/login" ||
        this.$route.path == "/404" ||
        this.$route.path == "/change-pass" ||
        this.$route.path == "/messages" ||
        this.$route.path == "/menu" ||
        this.$route.path == "/elections") {
        isSubPage = false;
      }
      //console.log(this.$route.name, 'name');
      /* if(this.$route.name == "story"){
        this.backButtonText = 'Story';
      }else if(this.$route.name == "profile"){
        this.backButtonText = 'Profile';
      } */
      return isSubPage;
    },
    async goBack() {
      this.$router.go(-1);
    },
    customButton(index) {
      //console.log('getting custom frank button');
      var source = "";
      if (index == 1) {
        if (this.$route.path == "/home") {
          source = "/assets/vocl-color.svg";
        } else {
          source = "/assets/vocl.svg";
        }
      }else if (index == 2) {
        if (this.$route.path == "/elections") {
          source = "/assets/coa-color.svg";
        } else {
          source = "/assets/coa.svg";
        }
      }
      return source;
    },
    async logout() {
      //console.log('submitted ' + this.username + ":" + this.password);
      let response = await this.$store.dispatch("auth/logout");
      if (response) {
        this.$store.commit("pikstream/logout");
        this.$store.commit("groups/logout");
        this.$store.commit("profile/logout");
        this.$store.commit('comments/logout');
        this.$store.commit('profile/resetProfile');
        
        this.$store.dispatch("header/general", {
          page: this.$t('header.login')
        });
        this.$router.replace('/login');
        //window.location = "/login";
      } else {
        // you can add condition here
      }
    },
    async openFeedbackModal() {
      const modal = await modalController.create({
        component: FeedbackModal,
        cssClass: "editpost-custom-class",
        componentProps: {
          title: this.$t('options.feedback'),
        },
      });
      return await modal.present();
    },
    async openHowToModal() {
      window.location.href = getEnv("HELP_LINK");
      /* const modal = await modalController
        .create({
          component: HowToModal,
          componentProps: {
            title: 'How To Guide',
          }
        })
      return await modal.present(); */
    },
    async presentLoginAlertConfirm() {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: this.$t('alerthead.login'),
        message: this.$t('alertmessage.login', {app: getEnv("APP_NAME")}),
        buttons: [
          {
            text: this.$t('header.signup'),
            cssClass: 'primary',
            handler: () => {
              this.signup();
            },
          },
          {
            text: this.$t('header.login'),
            handler: () => {
              this.login();
            },
          },
          {
            text: this.$t('buttons.cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },

        ],
      });
      return alert.present();
    },
    signup() {
      if(getEnv('APP_NAME') == 'Vocl1'){
        this.fsbnConnect('signup');
      }else{
        this.presentSignup('signup');
      }
    },
    login() {
      if(getEnv('APP_NAME') == 'Vocl1'){
        this.fsbnConnect('login');
      }else{
        this.presentSignup('login');
      }
      
    },
    async openLink(link_url) {
      if (link_url != '') {
        if (isPlatform('hybrid') || isPlatform('electron')) {
          await Browser.open({ url: link_url, presentationStyle: 'popover' });
        }else{
          window.open(link_url, '_blank');
        }
      }
    },
    async fsbnConnect(type) {
      let redirect = 'https://' + window.location.hostname + '/idauth';
      if (isPlatform('hybrid') || isPlatform('electron')) {
        redirect = 'https://' + getEnv('SHARE_HOSTNAME') + '/idauth';
      }
      const client_id = 'ceb0f61a-976b-4b29-a59f-55ec3f600d6f';
      let url_add = '';
      if(type=="signup") url_add = '&goto=signup';
      this.openLink('https://signin.frankspeech.com/OAuth/Start?redirect_uri='+redirect+'&client_id='+client_id+'&response_type=code&state='+type+url_add);
    },
    async presentSignup(type, button) {
      let title = this.$t('header.login');
      if (type == "signup") {
        title = this.$t('header.signup');
      }
      const modal = await modalController
        .create({
          component: SignupModal,
          cssClass: 'modal-custom-class',
          componentProps: {
            title: title,
            loginType: type,
            source: 'link'
          }
        })
      await modal.present();

      const { role } = await modal.onDidDismiss();
      if (role == 'loggedin') {
        if (button == 'buy') {
          this.openBuyModal();
        }
      }
    },
    async presentComingSoon() {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Switch Accounts",
        message: "The switch accounts feature is coming soon!",
        buttons: [
          {
            text: "OK",
            role: "cancel",
          },
        ],
      });
      return alert.present();
    },
    darkModeToggle() {
      if (this.darkMode == "false") {
        document.body.classList.add("dark");
        this.$store.dispatch("helpers/saveDarkMode", {
          darkModeSwitch: "true",
        });
      } else {
        document.body.classList.remove("dark");
        this.$store.dispatch("helpers/saveDarkMode", {
          darkModeSwitch: "false",
        });
      }
    },
    async registerPushes() {
      if (getEnv('APP_NAME') == 'Vocl') {
        let token = '';
        let id = '';
        // if you uncomment this it will ask for them to optin to pushes
        // we are going to let OneSignal handle this part
        /* if(window.plugins.OneSignal.User.pushSubscription.optedIn==false){
          window.plugins.OneSignal.User.pushSubscription.optIn();
        } */
        if(await window.plugins.OneSignal.User.pushSubscription != null){
          token = await window.plugins.OneSignal.User.pushSubscription.token;
          id = await window.plugins.OneSignal.User.pushSubscription.id;
        }
        if (id != '' && token != '') {
          let response = await this.$store.dispatch("helpers/notifyToken", {
            token_id: id,
            identifier: token,
            aws: null,
            android: null
          });
          if (response) {
            if (response['error'] != null) {
              console.log('Error: ' + response['error']);
            } else {
              //console.log(response, 'got it');
            }
          } else {
            console.log('Unkown error');
          }
        }
        // Ionic 5 Capacitor may need to use (window as any).plugins.OneSignal
        /* window.plugins.OneSignal.getDeviceState(async (stateChanges) => {
          console.log('OneSignal getDeviceState: ' + JSON.stringify(stateChanges));
        }); */
      } else {
        this.registerNotifications();
        this.addListeners();
        /* const getDeliveredNotifications = async () => {
          const notificationList = await PushNotifications.getDeliveredNotifications();
          console.log('delivered notifications', notificationList);
        }
        getDeliveredNotifications(); */
      }
    },
    async registerNotifications() {
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }

      await PushNotifications.register();
    },
    async addListeners() {
      await PushNotifications.addListener('registration', async (token) => {
        console.info('Registration token: ', token.value);
        let android = '';
        if (isPlatform('android')) {
          android = 'YES';
        }
        let response = await this.$store.dispatch("helpers/notifyToken", {
          token_id: token.value,
          identifier: '',
          aws: true,
          android: android
        });
        if (response) {
          if (response['error'] != null) {
            console.log('Error: ' + response['error']);
          } /*else {
            console.log(response, 'got it');
          }*/
        } else {
          console.log('Unkown error');
        }
      });

      await PushNotifications.addListener('registrationError', err => {
        console.error('Registration error: ', err.error);
      });

      await PushNotifications.addListener('pushNotificationReceived', notification => {
        console.log('Push notification received: ', notification);
      });

      await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        console.log('Push notification action performed', notification.actionId, notification.inputValue);
      });
    },
    async beginPolling() {
      //console.log('begin poll for messages');
      let pollTimeOut = 20000;
      setTimeout(() => {
        // note: this is a hack to fix an issue in ionic auto-grow
        this.count = this.count + 1;
      }, 100);

      //NOTE: turn off and test separately
      this.pollData = setInterval(() => {
        this.pollNotifications();
      }, pollTimeOut);
    },
    async pollNotifications() {
      //console.log('notifications poll: ' + new Date());
      if (this.$store.state.auth.token != '') {
        this.$store.dispatch("helpers/getNotifyBadges");
        if(isPlatform('hybrid') && getEnv('APP_NAME')!='Vocl'){ 
          await PushNotifications.removeAllDeliveredNotifications();
        }
      }
    },
    goToProfile() {
      if (isPlatform('hybrid')) { // only register pushes on phone devices
        this.registerPushes();
      }
      this.$router.replace({ name: 'profile', params: { id: this.myProfileId } });
    },
    goToNotifications() {
      if (isPlatform('hybrid')){ // only register pushes on phone devices
        this.registerPushes();
      }

      if(this.$route.path == "/notifications"){
        this.$store.commit("profile/setNotifications", null);
        this.$store.commit("profile/setRequests", null);
        this.$store.dispatch("profile/notifications", {
          totalList: 50,
          currentPage: 0,
          update: false,
          type: 'notifications'
        });
      }
      this.$store.dispatch("profile/requests");
      this.$router.replace('/notifications');
    },
    async searchApp() {
      this.$store.dispatch("header/general", {
        page: this.$t('header.search')
      });
      this.$router.replace('/searchall');
      /* if(this.$store.state.explore.likeProfileData.length==0){
        let top1 = await this.$store.dispatch("explore/topLikeProfiles");
        console.log(top1, 'return top likes');
        //let top2 = await this.$store.dispatch("explore/topCommentProfiles");
        if(top1){
          this.$router.replace('/searchall');
        }
      }else{
        console.log('cached');
        this.$router.replace('/searchall');
      }*/
    },
    getMargin() {
      var marginClass = "top-margin";
      if (isPlatform('ios') && isPlatform('hybrid')) {
        marginClass = "top-margin-ios";
      }
      return marginClass;
    },
    showMenu() {
      let canShowMenu = 'lg';
      if (this.toggleMenu == true) {
        canShowMenu = true;
      }
      if (this.$store.state.auth.token == '') {
        canShowMenu = false;
      }
      if (this.$route.name == "embed"){
        canShowMenu = false;
      }
      return canShowMenu;
    },
    navigate(url) {
      if (isPlatform('electron')) {
        return '#';
      } else {
        return url;
      }

    },
  },
  computed: {
    myProfilePic() {
      return this.$store.state.profile.pic;
    },
    myProfileId() {
      return this.$store.state.profile.id;
    },
    toggleMenu() {
      return this.$store.state.helpers.toggleMenu;
    },
    darkMode() {
      return this.$store.state.helpers.darkMode;
    }
  }
});
</script>
<style scoped>
ion-split-pane {
  --side-width: 270px;
  --side-max-width: 270px;
  /* --border: 1px solid lightgray; */
}


ion-thumbnail {
  --size: 40px;
  margin-left: 20px;
}

.title-text {
  font-size: 0.7em;
}

@media only screen and (min-width: 599px) {
  .title-text {
    font-size: 1.0em;
  }
}

@media only screen and (min-width: 1600px) {
  ion-split-pane {
    --side-width: 460px;
    --side-max-width: 460px;
  }

  .side-bar-menu {
    margin-left: 200px;
  }
}

@media only screen and (min-width: 2600px) {
  ion-split-pane {
    --side-width: 900px;
    --side-max-width: 900px;
  }

  .side-bar-menu {
    margin-left: 600px;
  }
}

@media only screen and (min-width: 3600px) {
  ion-split-pane {
    --side-width: 1200px;
    --side-max-width: 1200px;
  }

  .side-bar-menu {
    margin-left: 900px;
  }
}
</style>
