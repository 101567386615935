// initial state
import getEnv from '@/utils/env'

const REQUEST_URL = getEnv('REQUEST_URL');

import axios from 'axios';

const state = () => ({
  viewers:[],
  groupViewer:'',
  storyName:'',
  caption:'',
  visibility:0
})

// getters
const getters = {}

// actions
const actions = {
  async storyname(context, params) {
    // console.log('create: ' + state.postData);
    return axios.post(REQUEST_URL + "piklist/updatename",{
      id: params.storyid,
      name: params.name,
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      // context.commit('setRequest', res.data);
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async post(context, params) {
    // console.log('create: ' + state.postData);
    let description = params.description.split("\n").join("<br>");
    return axios.post(REQUEST_URL + "piklist/updateimages",{
      piklistid: params.storyid,
      pikid: params.id,
      description: description,
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      // context.commit('setRequest', res.data);
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async deletePost(context, params) {
    // console.log('create: ' + state.postData);
    return axios.delete(REQUEST_URL + "images/" + params.id,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async requestToAdd(context, params) {
    // console.log('create: ' + state.postData);
    return axios.post(REQUEST_URL + "requests",{
      piklist_id: params.storyid,
      type: '1',
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async getViewers(context, params) {
    // console.log('create: ' + state.postData);
    return axios.get(REQUEST_URL + "piklist/viewers?piklist_id=" + params.storyid,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      context.commit('setViewers', res.data);
      // return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async getGroups(context, params) {
    // console.log('create: ' + state.postData);
    return axios.get(REQUEST_URL + "piklist/viewergroup?piklist_id=" + params.storyid,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      context.commit('setGroup', res.data);
      // return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async getEditors(context, params) {
    // console.log('create: ' + state.postData);
    return axios.get(REQUEST_URL + "piklist/editors?piklist_id=" + params.storyid,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      context.commit('setViewers', res.data);
      // return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async getEditorGroups(context, params) {
    // console.log('create: ' + state.postData);
    return axios.get(REQUEST_URL + "piklist/editorgroup?piklist_id=" + params.storyid,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      context.commit('setGroup', res.data);
      // return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async updateViewers(context, params) {
    var choose = "";
    if(params.visibility==0){
      if(params.selectedFriends.length>0){
        choose = "choosefollowers";
      }else if(params.selectedGroup!=''){
        choose = "choosegroup";
      }else{
        choose = "me";
      }
    } else if(params.visibility==1){
      choose = "everyone";
    } else if(params.visibility==2){
      choose = "followers";
    }

    var members = [];
    for(var i = 0; i < params.selectedFriends.length; i++){
      members.push(params.selectedFriends[i].id);
    }
    var postParams = {
      choose: choose,
      members: members,
      group_id: params.selectedGroup.id,
      piklist_id: params.storyid,
      share_off: params.shareoff
    };

    return axios.post(REQUEST_URL + "piklist-viewer/create", postParams,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then((res) => {
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async updateEditors(context, params) {
    var choose = "me";
    if(params.selectedGroup!=''){
      choose = "choosegroup";
    }else if(params.selectedFriends.length>0){
      choose = "choosefollowers";
    }

    var members = [];
    for(var i = 0; i < params.selectedFriends.length; i++){
      members.push(params.selectedFriends[i].id);
    }
    return axios.post(REQUEST_URL + "piklist-editor/create",{
      choose: choose,
      members: members,
      group_id: params.selectedGroup.id,
      piklist_id: params.storyid,
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then((res) => {
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async pinpost(context, params) {
    return axios.post(REQUEST_URL + "image-pins",{
      post_id: params.postId,
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then((res) => {
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async unpinpost(context, params) {
    return axios.delete(REQUEST_URL + "image-pins/" + params.pinId,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then((res) => {
      return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
}

// mutations
const mutations = {
  setViewers(state, response) {
    //console.log(response);
    state.viewers = response;
  },
  setGroup(state, response) {
    //console.log(response);
    state.groupViewer = response;
  },
  setStoryname(state, response) {
    console.log(response);
    state.storyName = response;
  },
  setCaption(state, response) {
    //console.log(response);
    state.caption = response;
  },
  setVisibility(state, response) {
    //console.log(response);
    state.visibility = response;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
