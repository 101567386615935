// initial state
import getEnv from '@/utils/env'

const REQUEST_URL = getEnv('REQUEST_URL');

import axios from 'axios';

const state = () => ({
  usernameData: [],
  hashtagData: [],
  linkData: null,
  storySearchData: [],
  hashtagSearchData: [],
  friendSearchData: [],
  darkMode: localStorage.getItem('darkMode') || 'false',
  badges: '',
  messageBadges: '',
  viewsData: [],
  viewsTimestamp: '',
  loginRedirect: localStorage.getItem('loginRedirect') || '',
  autoFollow: localStorage.getItem('autoFollow') || '',
  reloadStream: [],
  commentToggle: '',
  searchToggle: '',
  postToggle: '',
  searchProfileData: [],
  searchStoryData: [],
  toggleMenu: false,
  states: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
    'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas',
    'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
    'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
    'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
    'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas',
    'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming']
})

// getters
const getters = {}

// actions
const actions = {

  usernameSearch(context, params) {
    axios.get(REQUEST_URL + 'user/suggest?search=' + params.search,
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        context.commit('setUsernames', res.data);
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  hashtagSearch(context, params) {
    axios.get(REQUEST_URL + 'image/hashtagsuggest?search=' + params.search,
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        context.commit('setHashtags', res.data);
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  linkPreview(context, params) {
    context.commit('setLinkUrl', undefined);
    let retryParams = '';
    if(params.retryLink!=undefined){
      retryParams = '&retry_link=' + params.retryLink;
    }
    axios.get(REQUEST_URL + 'image/linkpreview?link_url=' + encodeURIComponent(params.link_url) + retryParams,
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        if (res.data.message == null) {
          context.commit('setLinkUrl', res.data);
        } else {
          let linkData = {
            link_url: params.link_url,
            link_text: '',
            link_image: '',
            link_video: ''
          }
          context.commit('setLinkUrl', linkData);
        }
      })
      .catch(error => {
        let linkData = {
          link_url: params.link_url,
          link_text: '',
          link_image: '',
          link_video: ''
        }
        context.commit('setLinkUrl', linkData);
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  storyChoose(context, params) {
    // storefront_id add in if needed
    let groupAdd = '';
    if(params.usergroup!=null){
      groupAdd = '&groupid=' + params.usergroup.id;
    }
    //console.log('piklist/choose?type=' + params.type + '&currentPage=' + params.currentPage + '&totalList=' + params.totalList + groupAdd);
    axios.get(REQUEST_URL + 'piklist/choose?type=' + params.type + '&currentPage=' + params.currentPage + '&totalList=' + params.totalList + groupAdd,
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        if (params.update) {
          context.commit('setStories', res.data);
        } else {
          context.commit('setStorySearch', res.data);
        }
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  storySearch(context, params) {
    axios.get(REQUEST_URL + 'piklist/search?search=' + params.search + '&type=' + params.type,
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        context.commit('setStorySearch', res.data);
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async discoverSearch(context, params) {
    var token = context.rootState.auth.anonToken;
    if (context.rootState.auth.token != '') {
      token = context.rootState.auth.token;
    }

    return axios.get(REQUEST_URL + 'piklist/discoversearch?search=' + params.search,
      {
        headers: {
          'Authorization': token
        }
      })
      .then(res => {
        context.commit('setDiscoverSearch', res.data);
        return true;
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async discoverHashtagSearch(context, params) {
    var token = context.rootState.auth.anonToken;
    if (context.rootState.auth.token != '') {
      token = context.rootState.auth.token;
    }

    return axios.get(REQUEST_URL + 'piklist/discoverhashtagsearch?search=' + params.search,
      {
        headers: {
          'Authorization': token
        }
      })
      .then(res => {
        context.commit('setHashtagSearch', res.data);
        return true;
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async hashtagPosts(context, params) {
    var token = context.rootState.auth.anonToken;
    if (context.rootState.auth.token != '') {
      token = context.rootState.auth.token;
    }
    return axios.get(REQUEST_URL + 'image/byhashtag?search=' + params.search + '&currentPage=' + params.currentPage + '&totalList=' + params.totalList,
      {
        headers: {
          'Authorization': token
        }
      })
      .then(res => {
        if (params.update) {
          context.commit('setHashtagPostsUpdate', res.data);
        } else {
          context.commit('setHashtagPosts', res.data);
        }
        return true;
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async friendChoose(context, params) {
    // storefront_id add in if needed
    let mutual;
    if (params.mutual != undefined) mutual = '&mutual=1';
    return axios.get(REQUEST_URL + 'friend/mutual?currentPage=' + params.currentPage + '&totalList=' + params.totalList + mutual,
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        if (params.update) {
          context.commit('setFriends', res.data);
        } else {
          context.commit('setFriendSearch', res.data);
        }
        return true;
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  friendSearch(context, params) {
    // user_id optional
    axios.get(REQUEST_URL + 'friend/search?search=' + params.search,
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        context.commit('setFriendSearch', res.data);
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  saveDarkMode(context, params) {
    localStorage.setItem('darkMode', params.darkModeSwitch)
    context.commit('setDarkMode', params.darkModeSwitch);
  },
  async reportPost(context, params) {
    // console.log('create: ' + state.postData);
    return axios.post(REQUEST_URL + "report-images", {
      reason: params.reason,
      image_id: params.id,
    },
      {
        headers: {
          'Authorization': context.rootState.auth.token,
        }
      })
      .then(res => {
        // context.commit('setRequest', res.data);
        return res;
      })
      .catch(error => {
        return ({ 'error': error.message });
      });
  },
  async reportComment(context, params) {
    // console.log('create: ' + state.postData);
    return axios.post(REQUEST_URL + "report-comments", {
      reason: params.reason,
      comment_id: params.id,
    },
      {
        headers: {
          'Authorization': context.rootState.auth.token,
        }
      })
      .then(res => {
        // context.commit('setRequest', res.data);
        return res;
      })
      .catch(error => {
        return ({ 'error': error.message });
      });
  },
  async reportGroup(context, params) {
    // console.log('create: ' + state.postData);
    return axios.post(REQUEST_URL + "report-images", {
      reason: params.reason,
      group_id: params.group_id,
    },
      {
        headers: {
          'Authorization': context.rootState.auth.token,
        }
      })
      .then(res => {
        // context.commit('setRequest', res.data);
        return res;
      })
      .catch(error => {
        return ({ 'error': error.message });
      });
  },
  async showBlockedUsers(context) {
    return axios.get(REQUEST_URL + 'userblock',
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        context.commit('setFriendSearch', res.data);
        return res.data;
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //console.log(error.response.data);
          //console.log(error.response.status);
          //console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          //console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          //console.log('Error', error.message);
        }
        //.log(error.config);
        return ({ 'error': error.message });
        //return({'error': error.message + ' ' + REQUEST_URL});
      });
  },
  async blockUser(context, params) {
    return axios.post(REQUEST_URL + 'userblock', {
      friend_id: params.friend_id,
      report: params.report,
      reportflag: params.reportflag,
      blockflag: params.blockflag
    },
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        return res;
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //console.log(error.response.data);
          //console.log(error.response.status);
          //console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          //console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          //console.log('Error', error.message);
        }
        //.log(error.config);
        return ({ 'error': error.message });
        //return({'error': error.message + ' ' + REQUEST_URL});
      });
  },
  async shareLink(context, params) {
    return axios.post(REQUEST_URL + "webshares", {
      piklist_id: params.story_id,
      id: params.id,
    },
      {
        headers: {
          'Authorization': context.rootState.auth.token,
        }
      })
      .then(res => {
        // context.commit('setRequest', res.data);
        return res;
      })
      .catch(error => {
        return ({ 'error': error.message });
      });
  },
  async postViews(context) {
    // console.log('create: ' + state.postData);
    var token = context.rootState.auth.anonToken;
    if (context.rootState.auth.token != '') {
      token = context.rootState.auth.token;
    }

    var count = [];
    var posts = [];
    for (var i = 0; i < context.state.viewsData.length; i++) {
      posts.push(context.state.viewsData[i]);
      count.push(1);
    }
    // console.log(posts);
    // console.log(count);
    return axios.post(REQUEST_URL + "storyviews", {
      image_id: posts,
      count: count,
    },
      {
        headers: {
          'Authorization': token,
        }
      })
      .then(() => {
        context.commit('setViewTimestamp', null);
      })
      .catch(error => {
        return ({ 'error': error.message });
      });
  },
  async favoriteStory(context, params) {
    // console.log('create: ' + state.postData);
    return axios.post(REQUEST_URL + "piklist-follow/toggle", {
      piklist_id: params.id,
    },
      {
        headers: {
          'Authorization': context.rootState.auth.token,
        }
      })
      .then(res => {
        // context.commit('setRequest', res.data);
        return res;
      })
      .catch(error => {
        return ({ 'error': error.message });
      });
  },
  async feedback(context, params) {
    // console.log('create: ' + state.postData);
    return axios.post(REQUEST_URL + "user/sendfeedback", {
      name: 'Web App',
      feedback: params.feedback
    },
      {
        headers: {
          'Authorization': context.rootState.auth.token,
        }
      })
      .then(res => {
        // context.commit('setRequest', res.data);
        return res;
      })
      .catch(error => {
        return ({ 'error': error.message });
      });
  },
  getNotifyBadges(context) {
    axios.get(REQUEST_URL + 'push/total',
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        context.commit('setBadges', res.data);
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async clearBadges(context) {
    return axios.get(REQUEST_URL + 'push/clear',
      {
        headers: {
          'Authorization': context.rootState.auth.token
        }
      })
      .then(res => {
        return res.data;
        //context.commit('setBadges', res.data);
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async notifyToken(context, credentials) {
    return axios.post(REQUEST_URL + 'notify-token/create',
      {
        token_id: credentials.token_id,
        identifier: credentials.identifier,
        aws: credentials.aws,
        android: credentials.android
      },
      {
        headers: {
          'Authorization': context.rootState.auth.token,
        }
      })
      .then(res => {
        return res.data;
      })
      .catch(error => {
        return ({ 'error': error.message });
      });
  },
  async downloadImage(context, params) {
    return axios.get(REQUEST_URL + 'image/download?id=' + params.id,
      {
        responseType: 'blob',
        headers: {
          'Authorization': context.rootState.auth.token,
        }
      })
      .then(res => {
        return res;
      })
      .catch(error => {
        return ({ 'error': error.message });
      });
  },
}

// mutations
const mutations = {
  setDarkMode(state, mode) {
    state.darkMode = mode;
  },
  setToggleMenu(state, mode) {
    state.toggleMenu = mode;
  },
  setUsernames(state, response) {
    state.usernameData = response;
  },
  setHashtags(state, response) {
    state.hashtagData = response;
  },
  setLinkUrl(state, response) {
    state.linkData = response;
  },
  setStories(state, response) {
    let existingStories = state.storySearchData;
    let updatedArray = existingStories.concat(response);
    state.storySearchData = updatedArray;
  },
  setStorySearch(state, response) {
    state.storySearchData = response;
  },
  setClearDiscoverSearch(state) {
    state.searchProfileData = [];
    state.searchStoryData = [];
    state.hashtagSearchData = [];
  },
  setDiscoverSearch(state, response) {
    //state.discoverSearchData = response;
    state.searchProfileData = response[0]['All Users'];
    state.searchStoryData = response[0]['PikList Names'];
  },
  setHashtagSearch(state, response) {
    //console.log(response[0]['Hash Tags'], 'hash tags');
    state.hashtagSearchData = response[0]['Hash Tags'];
  },
  setHashtagPosts(state, response) {
    state.storySearchData = response;
  },
  setHashtagPostsUpdate(state, response) {
    let existingStories = state.storySearchData;
    let updatedArray = existingStories.concat(response);
    state.storySearchData = updatedArray;
  },
  setFriends(state, response) {
    let existingFriends = state.friendSearchData;
    let updatedArray = existingFriends.concat(response);
    state.friendSearchData = updatedArray;
  },
  setFriendSearch(state, response) {
    state.friendSearchData = response;
  },
  setBadges(state, response) {
    //console.log(response['newMessages'], 'set badges');
    if (response['newNews'] > 0) {
      state.badges = response['newNews'];
    } else {
      state.badges = '';
    }
    if (response['newMessages'] > 0) {
      state.messageBadges = response['newMessages'];
    } else {
      state.messageBadges = '';
    }
    if (response['postToggle'] != undefined) {
      state.postToggle = response['postToggle'];
    }
    if (response['commentToggle'] != undefined) {
      state.commentToggle = response['commentToggle'];
    }
    if (response['searchToggle'] != undefined) {
      state.searchToggle = response['searchToggle'];
    }
  },
  setViews(state, response) {
    if (state.viewsTimestamp == '') {
      state.viewsTimestamp = Date.now();
    }
    if (state.viewsData.indexOf(response[1]) == -1) {
      state.viewsData.push(response[1]);
    }
    // console.log(state.viewsData);
  },
  setViewTimestamp(state) {
    state.viewsTimestamp = '';
    state.viewsData = [];
  },
  setRedirectLogin(state, url) {
    localStorage.setItem('loginRedirect', url);
    state.loginRedirect = url;
  },
  setAutoFollow(state, followId) {
    localStorage.setItem('autoFollow', followId);
    state.autoFollow = followId;
  },
  /* setReloadStream(state, status) {
    let feedType = status.type;
    let feedSwitch = status.switch;
    if(feedSwitch=='on' && !state.reloadStream.includes(feedType)){
      state.reloadStream.push(feedType);
    }else{
      console.log(feedType, 'feed type');
      let feedIndex = state.reloadStream.indexOf(feedType);
      console.log(feedIndex, 'feed index');
      console.log(state.reloadStream, 'array');
      if(feedIndex!=-1) state.reloadStream.splice(feedIndex, 1);
    }
  },*/
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
