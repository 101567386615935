// initial state
import getEnv from '@/utils/env'

const REQUEST_URL = getEnv('REQUEST_URL');

import axios from 'axios';

const state = () => ({
  followResponseData: null,
  recommendResponseData: null,
  favoritesResponseData: null,
  myProfileResponseData: null,
  profileResponseData: [],
  electionResponseData: null,
  groupResponseData: [],
  storyResponseData: [],
  groupFeedResponseData: null,
  totalList: {
    follow: 7,
    recommend: 7,
    favorite: 7,
    myprofile: 7,
    profile: [],
    election: 7,
    group: [],
    groupfeed: 7,
    story: 1
  },
  lastId: {
    follow: 0,
    recommend: 0,
    favorite: 0,
    myprofile: 0,
    profile: [],
    election: 0,
    group: [],
    groupfeed: 0,
    story: 0
  },
  storyLoading: false,
  electionStates: [],
  electionCounties: [],
  electionStateName: '',
  electionCountyName: '',
  electionHashtag: ''
})

// profile -> stream -> story -> images
// home -> stream -> story -> images

// streamList - contains all of the stream ids (profile, newsfeed, featured, favorites, etc.), stories are stored as ids
// storyData - is an object of all of the story data included as a list, images are stored as ids
// imageData - is an object with the storyId as the index

// getters
const getters = {
  /* streamData: (state) => state.streams.map( id => state.streamData[id] ),
  storyData: (state) => state.stories.map( piklist_id => state.streamData[piklist_id] ),
  imageData: (state) => state.streams.map( piklist_id => state.streamData[piklist_id] ) */
}

// actions
const actions = {
  async getData(context, params) {
    var token = context.rootState.auth.anonToken;
    //var profileInfo = context.rootState.profile.profileData;
    if (context.rootState.auth.token != '') {
      token = context.rootState.auth.token;
    }
    return axios.get(REQUEST_URL + params.url,
      {
        headers: {
          'Authorization': token,
          'Api-Version': 2
        },
        timeout: 6000
      })
      .then(res => {
        if (params.streamType == "profile" || params.streamType == "ownerpreviewpremium") {
          var profileData = [res.data, params.userId];
          context.commit('setProfileRequest', profileData);
        } else if (params.streamType == "story"){
          context.commit('setStoryRequest', res.data);
        } else if (params.streamType == "myprofile" || params.streamType == "ownerpremium" || params.streamType == "premium") {
          context.commit('setMyProfileRequest', res.data);
        } else if (params.streamType == "favorites") {
          context.commit('setFavoriteRequest', res.data);
        } else if (params.streamType == "featured") {
          context.commit('setRecommendRequest', res.data);
        } else if (params.streamType == "elections") {
          context.commit('setElectionRequest', res.data);
        } else if (params.streamType == "groupfeed") {
          context.commit('setGroupFeedRequest', res.data);
        } else if (params.streamType == "grouppage" || params.streamType == "grouppinned") {
          var groupData = [res.data, params.groupId];
          context.commit('setGroupRequest', groupData);
        } else {
          context.commit('setFollowRequest', res.data);
        }
        return true;
      })
      .catch(async error => {
        if (error.message == 'Request failed with status code 401') {
          let response = await context.dispatch("auth/refreshToken", {}, { root: true });
          if (response!=undefined)
            if(response.error != '') {
              return ({ 'error': response.error + ' ' + REQUEST_URL });
            }else{
              //console.log('refreshed');
              return true;
            }
        }else{
          console.log(error);
          return({'error': error}); 
        }
      });
  },
  async pageData(context, params) {
    //console.log(params.streamType, 'stream type');
    var token = context.rootState.auth.anonToken;
    if (context.rootState.auth.token != '') {
      token = context.rootState.auth.token;
    }
    //console.log('pikstream/grabv3?totalList=' + params.total_list + '&lastId=' + params.last_id + params.url_addon, 'page');
    return axios.get(REQUEST_URL + 'pikstream/grabv3?totalList=' + params.total_list + '&lastId=' + params.last_id + params.url_addon,
      {
        headers: {
          'Authorization': token,
          'Api-Version': 2
        }
      })
      .then(res => {
        if (params.streamType == "profile" || params.streamType == "ownerpreviewpremium") {
          var profileData = [res.data, params.userId, params.last_id];
          context.commit('addToProfileRequest', profileData);
        } else if (params.streamType == "myprofile" || params.streamType == "ownerpremium" || params.streamType == "premium") {
          context.commit('addToMyProfileRequest', [res.data, params.last_id]);
        } else if (params.streamType == "favorites") {
          context.commit('addToFavoriteRequest', [res.data, params.last_id]);
        } else if (params.streamType == "featured") {
          context.commit('addToRecommendRequest', [res.data, params.last_id]);
        } else if (params.streamType == "elections") {
          context.commit('addToElectionRequest', [res.data,params.last_id]);
        } else if (params.streamType == "groupfeed") {
          context.commit('addToGroupFeedRequest', [res.data,params.last_id]);
        } else if (params.streamType == "grouppage" || params.streamType == "grouppinned") {
          var groupData = [res.data, params.groupId, params.last_id];
          context.commit('addToGroupRequest', groupData);
        } else {
          context.commit('addToFollowRequest', [res.data, params.last_id]);
        }
        /*if (params.streamType == "profile" || params.streamType == "ownerpreviewpremium" || params.streamType == "favorites" || params.streamType == "story" || params.streamType == "featured" || params.streamType == "elections") {
          var data = [res.data, params.userId];
          if (params.streamType == "favorites") data = [res.data, 1];
          if (params.streamType == "story") data = [res.data, 2];
          if (params.streamType == "featured" || params.streamType == "elections") data = [res.data, 3];
          context.commit('addToProfileRequest', data);
        } else if (params.streamType == "myprofile" || params.streamType == "ownerpremium" || params.streamType == "premium") {
          context.commit('addToMyProfileRequest', res.data);
        } else {
          context.commit('addToRequest', res.data);
        }*/
        return true;
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async pageStory(context, params) {
    var token = context.rootState.auth.anonToken;
    if (context.rootState.auth.token != '') {
      token = context.rootState.auth.token;
    }
    return axios.get(REQUEST_URL + params.url,
      {
        headers: {
          'Authorization': token
        }
      })
      .then(res => {
        return res.data;
        /*if (params.streamType == "profile" || params.streamType == "ownerpreviewpremium" || params.streamType == "favorites" || params.streamType == "story" || params.streamType == "featured" || params.streamType == "elections") {
          var data = [res.data, params.userId];
          if (params.streamType == "favorites") data = [res.data, 1];
          if (params.streamType == "story") data = [res.data, 2];
          if (params.streamType == "featured" || params.streamType == "elections") data = [res.data, 3];
          context.commit('addToProfileStory', data);
        } else if (params.streamType == "myprofile" || params.streamType == "ownerpremium" || params.streamType == "premium") {
          context.commit('addToMyProfileStory', res.data);
        } else if (params.streamType == "postsView") {
          return res.data;
        } else {
          context.commit('addToStory', res.data);
        }
        return true;*/
        //context.commit('addToStory', res.data);
      }).catch(error => {
        console.log(error.message, 'error');
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async imageData(context, params) {
    //console.log(params.url, 'get image data');
    var token = context.rootState.auth.anonToken;
    if (context.rootState.auth.token != '') {
      token = context.rootState.auth.token;
    }
    return axios.get(REQUEST_URL + params.url,
      {
        headers: {
          'Authorization': token
        }
      })
      .then(res => {
        //console.log(res.data);
        return res.data;
      })
      .catch(error => {
        return ({ 'error': error.message + ' ' + REQUEST_URL });
      });
  },
  async getStream(context, params) {
    var urlAddon = '';
    //console.log('stream name variable: ' + params.streamName);
    // console.log('userid variable: ' + params.userid);
    if (params.streamName == "profile" || params.streamName == "myprofile") {
      urlAddon = 'streamtype=profile&';
    } else if (params.streamName == "premium") {
      urlAddon = 'streamtype=premium&';
    } else if (params.streamName == "favorites") {
      urlAddon = 'streamtype=favorites&';
    } else if (params.streamName == "ownerpremium") {
      urlAddon = 'streamtype=ownerpremium&';
    } else if (params.streamName == "ownerpreviewpremium") {
      urlAddon = 'streamtype=ownerpreviewpremium&';
    } else if (params.streamName == "story") {
      urlAddon = 'streamtype=story&';
    } else if (params.streamName == "featured") {
      urlAddon = 'streamtype=featured&';
    } else if (params.streamName == "elections") {
      urlAddon = 'streamtype=elections&';
      if(context.state.electionStateName!=''){
        urlAddon = urlAddon.concat('statename=' + encodeURIComponent(context.state.electionStateName) + '&');
        //console.log(context.state.electionStateName, 'election state name');
      }
      if(context.state.electionCountyName!=''){
        urlAddon = urlAddon.concat('countyname=' + encodeURIComponent(context.state.electionCountyName) + '&');
        ///console.log(context.state.electionCountyName, 'election county name');
      }
      if(context.state.electionHashtag!=''){
        urlAddon = urlAddon.concat('hashtag=' + encodeURIComponent(context.state.electionHashtag) + '&');
        //console.log(context.state.electionHashtag, 'election hashtag name');
      }
    } else if (params.streamName == "grouppage") {
      urlAddon = 'streamtype=grouppage&';
    } else if (params.streamName == "grouppinned") {
      urlAddon = 'streamtype=grouppinned&';
    } else if (params.streamName == "groupfeed") {
      urlAddon = 'streamtype=groupfeed&';
    }
      
    if (params.total_list != null) {
      urlAddon = urlAddon.concat('totalList=' + params.total_list + '&');
    }
    if (params.last_id != null) {
      urlAddon = urlAddon.concat('lastId=' + params.last_id + '&');
    }
    if (params.filter != null) {
      urlAddon = urlAddon.concat('filterProfile=' + params.filter + '&');
    }
    if (params.imageid != null && !Number.isNaN(parseInt(params.imageid))) {
      urlAddon = urlAddon.concat('pikid=' + params.imageid + '&');
    }
    if (params.userid != undefined && !Number.isNaN(parseInt(params.userid))) {
      urlAddon = urlAddon.concat('streamuserid=' + params.userid + '&');
    }
    if (params.groupid != undefined && !Number.isNaN(parseInt(params.groupid))) {
      urlAddon = urlAddon.concat('group_id=' + params.groupid + '&');
    }
    if (params.storyid != null && !Number.isNaN(parseInt(params.storyid))) {
      urlAddon = urlAddon.concat('id=' + params.storyid + '&');
    }
    urlAddon = urlAddon.slice(0, -1).trim();
    //console.log("pikstream/grab?totalList=" + params.total_list + "&currentPage=" + params.current_page + urlAddon);
    // console.log('option = ' + option);
    /* if(option=="page"){
      console.log('called page fetch');
      this.$store.dispatch("pikstream/pageData", {
        url: "pikstream/grab?totalList=" + this.total_list + "&currentPage=" + this.current_page + urlAddon,
        streamType: this.$route.name,
        userId: params.userid
      });
    }else{ */
    //console.log("pikstream/grabv3?" + urlAddon, 'streamUrl');
    //console.log("pikstream/grab?totalList=" + params.total_list + "&currentPage=" + params.current_page + urlAddon, 'called data fetch');
    let userId = '';
    if(!Number.isNaN(parseInt(params.userid))){
      userId = params.userid;
    }
    let groupId = '';
    if(!Number.isNaN(parseInt(params.groupid))){
      groupId = params.groupid;
    }
    
    //console.log(params.imageid);
    //console.log(!Number.isNaN(parseInt(params.imageid)));
    let response = await context.dispatch("getData", {
      url: "pikstream/grabv3?" + urlAddon,
      streamType: params.streamName,
      userId: userId,
      groupId: groupId
    });
    return response;
    // this.$store.dispatch("pikstream/getData", "pikstream/grab?totalList=" + this.total_list + "&currentPage=" + this.current_page + urlAddon);
    //context.dispatch("profile/info", "");
  },
  async getStateData(context) {
    var token = context.rootState.auth.anonToken;
    //var profileInfo = context.rootState.profile.profileData;
    if (context.rootState.auth.token != '') {
      token = context.rootState.auth.token;
    }
    axios.get(REQUEST_URL + '/pikstream/show-states',
      {
        headers: {
          'Authorization': token
        }
      })
      .then(res => {
        context.commit('addElectionStates', res.data);
      })
      .catch(async error => {
        return({'error': error}); 
      });
  },
  getCountyData(context, params) {
    var token = context.rootState.auth.anonToken;
    if (context.rootState.auth.token != '') {
      token = context.rootState.auth.token;
    }
    axios.get(REQUEST_URL + '/pikstream/state-counties?state=' + params.state,
      {
        headers: {
          'Authorization': token
        }
      })
      .then(res => {
        context.commit('addElectionCounties', res.data);
      })
      .catch(async error => {
        return({'error': error}); 
      });
  },
}

// mutations
const mutations = {
  setFollowRequest(state, response) {
    if(response!=null && response.length==0){
      response = [{
        'noposts': 1
      }];
    }
    state.followResponseData = response;
    state.totalList.follow = 7;
    state.lastId.follow = 0;
  },
  setProfileRequest(state, response) {
    let responseData = response[0];
    if(responseData.length==0){
      responseData = [{
        'noposts': 1
      }];
    }
    const userId = response[1];
    state.profileResponseData[userId] = responseData;
    state.totalList.profile[userId] = 7;
    state.lastId.profile[userId] = 0;
  },
  setProfileRequestNull(state, response) {
    const userId = response;
    state.profileResponseData[userId] = null;
    state.totalList.profile[userId] = 7;
    state.lastId.profile[userId] = 0;
  },
  setGroupRequest(state, response) {
    let responseData = response[0];
    if(responseData.length==0){
      responseData = [{
        'noposts': 1
      }];
    }
    const groupId = response[1];
    state.groupResponseData[groupId] = responseData;
    state.totalList.group[groupId] = 7;
    state.lastId.group[groupId] = 0;
  },
  setGroupRequestNull(state, response) {
    const groupId = response;
    state.groupResponseData[groupId] = null;
    state.totalList.group[groupId] = 7;
    state.lastId.group[groupId] = 0;
  },
  setMyProfileRequest(state, response) {
    if(response!=null && response.length==0){
      response = [{
        'noposts': 1
      }];
    }
    state.myProfileResponseData = response;
    state.totalList.myprofile = 7;
    state.lastId.myprofile = 0;
  },
  setFavoriteRequest(state, response) {
    if(response!=null && response.length==0){
      response = [{
        'noposts': 1
      }];
    }
    state.favoritesResponseData = response;
    state.totalList.favorite = 7;
    state.lastId.favorite = 0;
  },
  setElectionRequest(state, response) {
    if (response == null) {
      state.electionResponseData = response;
      state.totalList.election = 7;
      state.lastId.election = 0;
    } else {
      let responseData = response;
      if (responseData.length == 0) {
        responseData = [{
          'noposts': 1
        }];
      }
      state.electionResponseData = responseData;
      state.totalList.election = 7;
      state.lastId.election = 0;
    }
  },
  setRecommendRequest(state, response) {
    state.recommendResponseData = response;
    state.totalList.recommend = 7;
    state.lastId.recommend = 0;
  },
  setGroupFeedRequest(state, response) {
    let responseData = response;
    if(responseData.length==0){
      responseData = [{
        'noposts': 1
      }];
    }
    state.groupFeedResponseData = responseData;
    state.totalList.groupfeed = 7;
    state.lastId.groupfeed = 0;
  },
  setGroupFeedRequestNull(state){
    state.groupFeedResponseData =  null;
    state.totalList.groupfeed = 7;
    state.lastId.groupfeed = 0;
  },
  setStoryRequest(state, response) {
    const responseData = response[0];
    const imageId = responseData.pik_id;
    state.storyResponseData[imageId] = responseData;
  },
  setStoryLoading(state, response) {
    state.storyLoading = response;
  },
  addToFollowRequest(state, response) {
    const responseData = response[0];
    const lastId = response[1];
    state.lastId.follow = lastId;

    var j = state.followResponseData.length;
    const max = responseData.length;
    const min = 0;
    for (let i = min; i < max; i++) {
      state.followResponseData[j] = responseData[i];
      j = j + 1;
    }
  },
  addToProfileRequest(state, response) {
    const responseData = response[0];
    const userId = response[1];
    const lastId = response[2];
    state.lastId.profile[userId] = lastId;

    var j = state.profileResponseData[userId].length;
    const max = responseData.length;
    const min = 0;
    for (let i = min; i < max; i++) {
      state.profileResponseData[userId][j] = responseData[i];
      j = j + 1;
    }
  },
  addToMyProfileRequest(state, response) {
    const responseData = response[0];
    const lastId = response[1];
    state.lastId.myprofile = lastId;

    var j = state.myProfileResponseData.length;
    const max = responseData.length;
    const min = 0;
    for (let i = min; i < max; i++) {
      state.myProfileResponseData[j] = responseData[i];
      j = j + 1;
    }
  },
  addToFavoriteRequest(state, response) {
    const responseData = response[0];
    const lastId = response[1];
    state.lastId.favorite = lastId;

    var j = state.favoritesResponseData.length;
    const max = responseData.length;
    const min = 0;
    for (let i = min; i < max; i++) {
      state.favoritesResponseData[j] = responseData[i];
      j = j + 1;
    }
  },
  addToElectionRequest(state, response) {
    const responseData = response[0];
    const lastId = response[1];
    state.lastId.election = lastId;

    var j = state.electionResponseData.length;
    const max = responseData.length;
    const min = 0;
    for (let i = min; i < max; i++) {
      state.electionResponseData[j] = responseData[i];
      j = j + 1;
    }
  },
  addToRecommendRequest(state, response) {
    const responseData = response[0];
    const lastId = response[1];
    state.lastId.recommend = lastId;

    var j = state.recommendResponseData.length;
    const max = responseData.length;
    const min = 0;
    for (let i = min; i < max; i++) {
      state.recommendResponseData[j] = responseData[i];
      j = j + 1;
    }
  },
  addToGroupRequest(state, response) {
    const responseData = response[0];
    const groupId = response[1];
    const lastId = response[2];
    state.lastId.group[groupId] = lastId;

    var j = state.groupResponseData[groupId].length;
    const max = responseData.length;
    const min = 0;
    for (let i = min; i < max; i++) {
      state.groupResponseData[groupId][j] = responseData[i];
      j = j + 1;
    }
  },
  addToGroupFeedRequest(state, response) {
    const responseData = response[0];
    const lastId = response[1];
    state.lastId.groupfeed = lastId;

    var j = state.groupFeedResponseData.length;
    const max = responseData.length;
    const min = 0;
    for (let i = min; i < max; i++) {
      state.groupFeedResponseData[j] = responseData[i];
      j = j + 1;
    }
  },
  addElectionStates(state, response){
    state.electionStates = response;
  },
  addElectionCounties(state, response){
    state.electionCounties = response;
  },
  setElectionFilter(state, response){
    state.electionStateName = response[0];
    state.electionCountyName = response[1];
    state.electionHashtag = response[2];
  },
  setEdit(state, response) {
    let streams = [];
    let type = response.type;
    streams[0] = state.myProfileResponseData;
    streams[1] = state.followResponseData;
    streams[2] = state.recommendResponseData;
    streams[3] = state.electionResponseData;
    streams[4] = state.groupFeedResponseData;
    streams[5] = state.favoritesResponseData;
    if(response.groupid!=undefined) streams[6] = state.groupResponseData[response.groupid];
    if(response.userid!=undefined) streams[7] = state.profileResponseData[response.userid];
    
    //console.log('edit it', response);
    //console.log('stream data', streams);
    //console.log('stream length', streams[0]);
    for(let j=0; j<streams.length; j++){
      let stream = streams[j];
      if(stream!=null){
        for (let i = 0; i < stream.length; i++) {
          let story = stream[i];
          if(type=='caption' && story.pik_id == response.imageid){
            story.description = response.description;
          }else if(type=='delete' && story.pik_id == response.imageid){
            let length = i;
            if(i==0) length = 1;
            stream.splice(i,length);
          }else if(type=='storyname' && story.piklist_id == response.story.piklist_id){
            story.piklist_name = response.storyname;
          }else if(type=='visbility' && story.piklist_id == response.story.piklist_id){
            story.visibility = response.visibility;
          }
        }
      }
    }
  },
  logout(state){
    state.followResponseData =  null;
    state.recommendResponseData =  null;
    state.favoritesResponseData =  null;
    state.myProfileResponseData =  null;
    state.profileResponseData =  [];
    state.electionResponseData =  null;
    state.groupResponseData =  [];
    state.storyResponseData =  [];
    state.groupFeedResponseData =  null;
    state.totalList =  {
      follow: 7,
      recommend: 7,
      favorite: 7,
      myprofile: 7,
      profile: [],
      election: 7,
      group: [],
      groupfeed: 7,
      story: 1
    };
    state.lastId =  {
      follow: 0,
      recommend: 0,
      favorite: 0,
      myprofile: 0,
      profile: [],
      election: 0,
      group: [],
      groupfeed: 0,
      story: 0
    };
    state.storyLoading= false;
  }  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
